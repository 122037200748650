import { useUserMeAccessQuery } from "crud/me_access";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setContractIdFilter } from "store/insights/insightsActions";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RangeSelect } from "../RangeSelect/RangeSelect";

export const EstimatedExposure = () => {

    const dispatch = useDispatch();

    const { data: userMeAccessData } = useUserMeAccessQuery();

    const policyData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").policyData,
    );

    const all_estimated_exposure: number[] | undefined = policyData?.data
    ? policyData?.data.map((data) => data.estimated_exposure)
    : [];

    return (
        <>
            {
                userMeAccessData &&
                userMeAccessData.has_policy_access &&
                policyData?.data && (
                    <RangeSelect
                        minValue={Math.min(
                            ...all_estimated_exposure,
                        )}
                        maxValue={Math.max(
                            ...all_estimated_exposure,
                        )}
                        title={"Estimated Exposure"}
                        format
                        prefix={"$"}
                        onSubmit={(value: {
                            min: number;
                            max: number;
                        }) => {
                            let contractIds: number[] | null =
                                policyData!.data
                                    .filter(
                                        (data) =>
                                            data.estimated_exposure >=
                                                value.min &&
                                            data.estimated_exposure <=
                                                value.max,
                                    )
                                    .map(
                                        (data) =>
                                            data.MIS_ContractID,
                                    );

                            if (contractIds && !contractIds.length)
                                contractIds = null;

                            dispatch(
                                setContractIdFilter({
                                    contractIds: contractIds,
                                }),
                            );
                        }}
                    />
            )}
        </>
    );
};
