import React from "react";
import { mdiMapMarker } from "@mdi/js";

import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";

import cx from "classnames";
import { getCssVar } from "utils/CSSHelpers";
import classes from "./LatLongControl.module.css";

function LatLongControl() {
    const mousePos = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").mousePosition,
    );

    let latitude = mousePos.lat || 0;
    let longitude = mousePos.lng || 0;

    while (longitude > 180 || longitude < -180) {
        if (longitude > 180) {
            longitude = longitude - 360;
        }
        if (longitude < -180) {
            longitude = longitude + 360;
        }
    }

    return (
        <div className={cx(classes.Container)}>
            <div className={cx(classes.LatLongDisplay)}>
                <span>lat: {latitude.toFixed(5)}</span>
                <span>lng: {longitude.toFixed(5)}</span>
            </div>
            <div className={classes.Marker}>
                <Icon
                    color={getCssVar("--highlight-color")}
                    data-for="LatLongControlToolTip"
                    data-tip="Right click anywhere on the map to capture coordinates"
                    path={mdiMapMarker}
                />
            </div>

            <ReactTooltip id={"LatLongControlToolTip"} />
        </div>
    );
}

export default LatLongControl;
