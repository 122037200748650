import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { CircleLayer, Layer, Marker, Source, SourceProps } from "react-map-gl";
import CreateDonutChart from "utils/Clusters";
import { MarkerProps } from "./DataLayers";
import { useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { insightsClusterColor, insightsClusterStroke } from "./ClusterPaints";
import { CapitaliseWord } from "utils/String";
import { AssessmentType } from "store/system/systemTypes";
import { filterGeojsonByProperties } from "utils/Geojson";
import { CirclePaint } from "mapbox-gl";
import _ from "lodash";

interface MemoSourceProps {
    layers: ReactElement;
    sourceProps: SourceProps;
}

// memoized source creation for insights layer
export const MemoSource = React.memo((props: MemoSourceProps) => {
    return (
        <Source key={props.sourceProps.id} {...props.sourceProps}>
            {props.layers}
        </Source>
    );
});

interface InsightsClusterLayersProps {
    locationLabels: boolean;
    assessmentType: AssessmentType;
}

const InsightsClusterLayers: FC<InsightsClusterLayersProps> = ({
    locationLabels,
    assessmentType,
}: InsightsClusterLayersProps) => {
    return (
        <>
            <Layer
                id="insightsClusterCircle"
                key="insightsClusterCircle"
                type="circle"
                filter={["has", "point_count"]}
                paint={{
                    "circle-color": insightsClusterColor[assessmentType],
                    "circle-radius": [
                        "step",
                        ["get", "point_count"],
                        10,
                        10,
                        20,
                        100,
                        30,
                        750,
                        40,
                        10000,
                        47,
                    ],
                    "circle-stroke-color":
                        insightsClusterStroke[assessmentType],
                    "circle-stroke-width": 2,
                    "circle-opacity": 0.8,
                }}
                source="insightsSourcetrue"
                beforeId={locationLabels ? "road-label" : ""}
            />
            <Layer
                key={"insightsClusterCount"}
                id="insightsClusterCount"
                type="symbol"
                filter={["has", "point_count"]}
                layout={{
                    "text-field": "{point_count_abbreviated}",
                    "text-font": [
                        "DIN Offc Pro Medium",
                        "Arial Unicode MS Bold",
                    ],
                    "text-size": 12,
                    "text-allow-overlap": true,
                }}
                paint={{}}
                source="insightsSourcetrue"
                beforeId={locationLabels ? "road-label" : ""}
            />
        </>
    );
};

interface InsightsPointLayerProps {
    clusterToggle: boolean;
    locationLabels: boolean;
    assessmentType: AssessmentType;
}

const InsightsPointLayer: FC<InsightsPointLayerProps> = ({
    clusterToggle,
    locationLabels,
    assessmentType,
}: InsightsPointLayerProps): ReactElement | null => {
    let insightsStyle = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsStyles,
    );

    const stylePaint = _.get(insightsStyle, assessmentType)?.style?.paint
    if (!stylePaint) {
        return null;
    }

    let id = `${CapitaliseWord(assessmentType)} Assessment`;

    const layerProps: CircleLayer = {
        id: id,
        type: "circle",
        paint: stylePaint as CirclePaint,
        filter: ["!", ["has", "point_count"]],
        source: "insightsSource" + clusterToggle,
    };

    return (
        <Layer
            key={id}
            {...layerProps}
            beforeId={locationLabels ? "road-label" : ""}
        />
    );
};

interface InsightsLayersProps {}

export const InsightsLayers: FC<InsightsLayersProps> = (
    props: InsightsLayersProps,
): ReactElement | null => {
    let clusterToggle = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").clusterToggle,
    );
    let insightsGeojson = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsGeojson,
    );
    let insightsVisible = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsVisible,
    );
    let locationLabels = useSelector(
        (state) => getStoreAtNamespaceKey(state, "report").locationLabels,
    );
    let insightsFilters = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsFilters,
    );
    let assessmentType = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    let insightsDescription = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "insights").insightsData?.description,
    );

    let isInsightsLoaded = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").isInsightsLoaded,
    );

    let [geojsonData, setGeojsonData] = useState<
        GeoJSON.FeatureCollection | undefined
    >(undefined);

    let memoFilteredGeojson = useMemo(() => {
        if (!insightsGeojson || !insightsVisible) return;

        return filterGeojsonByProperties(insightsGeojson, [
            {
                property: `${CapitaliseWord(assessmentType)} Layer Assessment`,
                values: insightsFilters.damageLabels,
            },
            {
                property: "MIS_ContractID",
                values: insightsFilters.contractIds,
            },
        ]);
    }, [insightsFilters, insightsVisible, insightsGeojson, assessmentType]);

    useEffect(() => {
        if (!insightsVisible) return;

        if (!insightsGeojson) {
            setGeojsonData(undefined);
        } else {
            setGeojsonData(memoFilteredGeojson);
        }
    }, [
        insightsFilters,
        insightsVisible,
        insightsGeojson,
        assessmentType,
        memoFilteredGeojson,
        isInsightsLoaded,
    ]);

    if (!geojsonData || !insightsVisible || !insightsDescription) return null;

    let sourceProps: SourceProps = {
        id: "insightsSource" + clusterToggle,
        type: "geojson",
        data: geojsonData,
        cluster: clusterToggle,
    };

    if (clusterToggle) {
        sourceProps = {
            ...sourceProps,
            clusterRadius: 100,
            clusterMaxZoom: 10,
        };
    }

    return (
        <>
            {isInsightsLoaded && (
                <Source
                    key={"insightsClustered" + clusterToggle}
                    {...sourceProps}
                >
                    <InsightsPointLayer
                        clusterToggle={clusterToggle}
                        locationLabels={locationLabels}
                        assessmentType={assessmentType}
                    />
                    {clusterToggle && (
                        <InsightsClusterLayers
                            locationLabels={locationLabels}
                            assessmentType={assessmentType}
                        />
                    )}
                </Source>
            )}
        </>
    );
};

export const InsightsDonutMarker = (props: MarkerProps) => {
    const insightsStyles = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").insightsStyles,
    );

    const assessmentType = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").assessmentType,
    );

    if (
        props.marker &&
        props.marker.lnglat &&
        props.marker.cluster &&
        insightsStyles &&
        insightsStyles[assessmentType]?.style?.paint
    ) {
        return (
            <Marker
                anchor="top-left"
                longitude={props.marker.lnglat[0]}
                latitude={props.marker.lnglat[1]}
            >
                {CreateDonutChart({
                    cluster: props.marker.cluster,
                    insightsStyle: insightsStyles[assessmentType],
                })}
            </Marker>
        );
    } else {
        return <></>;
    }
};
