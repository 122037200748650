import { Reducer } from "redux";
import Papa from "papaparse";
import {
    ADD_POLICY_INSIGHTS,
    AddPolicyInsightsAction,
    InsightsActionTypes,
    InsightsState,
    InsightsType,
    REMOVE_INSIGHTS_DATA,
    SET_EVENT_ID,
    SET_FETCHING_STATUS,
    TOGGLE_DAMAGE_LABEL_FILTER,
    SET_INSIGHTS_LAYER_VIEW,
    SET_INSIGHTS_LAYER_VISIBILITY,
    SET_INSIGHTS_LOADED,
    SET_INSIGHTS_TYPE,
    SET_SELECTED_PORTFOLIO,
    LOAD_INSIGHTS,
    SetEventIdAction,
    SetFetchingStatusAction,
    toggleDamageLabelFilterAction,
    SetInsightsLayerViewAction,
    SetInsightsLayerVisibilityAction,
    SetInsightsLoadedAction,
    SetInsightsTypeAction,
    setSelectedPortfolioAction,
    TOGGLE_CLUSTER,
    TOGGLE_MARKER,
    ToggleClusterAction,
    ToggleMarkerAction,
    LoadInsightsAction,
    SET_ASSESSMENT_TYPE,
    SetAssessmentTypeAction,
    CLEAR_INSIGHTS_FILTERS,
    SET_CONTRACT_ID_FILTER,
    SetFilteredIndicesAction,
    SET_FILTERED_INDICES,
    CLEAR_FILTERED_INDICES,
    SET_SELECTED_PERIL,
    SetSelectedPerilAction,
    setContractIdFilterAction,
    TOGGLE_INSIGHTS_VISIBILITY,
    SetRevisionsAction,
    SET_REVISIONS,
    SetSelectedRevisionIdAction,
    SET_SELECTED_REVISION_ID,
    SetFilteredPropertiesAction,
    SET_FILTERED_PROPERTIES,
    SET_ASSESSMENT_FILTERS,
    SetAssessmentFiltersAction,
    assessmentFilters,
    SET_ALL_INSIGHTS_STYLES,
    SetAllInsightsStylesAction,
    SET_INSIGHTS_STYLES,
    SetInsightsStylesAction,
    UpdateAssessmentStylesAction,
    UPDATE_ASSESSMENT_STYLES,
    SetInsightsGeojsonAction,
    SET_INSIGHTS_GEOJSON,
} from "./insightsTypes";
import { hasNoAffectedProperties } from "../../utils/FilterFeatures";
import { formatExposureClaims } from "components/Pages/Report/DashboardComponents/Insights/utils";
import {
    filterGeojsonByIndex,
    filterGeojsonByProperties,
    geoJSONFromPapaparse,
} from "utils/Geojson";

const initState: InsightsState = {
    isFetchingInsights: false,
    isInsightsLoaded: false,
    insightsType: null,
    selectedPortfolio: null,
    selectedRevisionId: "",
    eventId: "",
    insightsData: null,
    policyData: null,
    locationData: null,
    insightsFilters: {
        damageLabels: [],
        contractIds: null,
    },
    revisions: [],
    selectedPeril: null,
    insightsGeojson: null,
    clusterToggle: true,
    assessmentType: "exposure",
    insightsVisible: true,
    insightsViewOn: "both",
    marker: null,
    assessmentFilters: {
        exposure: [],
        claims: [],
    },
    allInsightsStyles: [],
    insightsStyles: null,
};

export const insightsReducer: Reducer<InsightsState, InsightsActionTypes> = (
    state = initState,
    action,
): InsightsState => {
    switch (action.type) {
        case SET_INSIGHTS_TYPE:
            return Reduce_SetInsightsType(state, action);
        case SET_INSIGHTS_LOADED:
            return Reduce_SetInsightsLoaded(state, action);
        case SET_INSIGHTS_LAYER_VISIBILITY:
            return Reduce_SetInsightsLayerVisibility(state, action);
        case TOGGLE_INSIGHTS_VISIBILITY:
            return Reduce_ToggleInsightsVisibility(state);
        case SET_INSIGHTS_LAYER_VIEW:
            return Reduce_SetInsightsLayerView(state, action);
        case TOGGLE_DAMAGE_LABEL_FILTER:
            return Reduce_toggleDamageLabelFilter(state, action);
        case CLEAR_INSIGHTS_FILTERS:
            return Reduce_ClearAllFilters(state);
        case SET_CONTRACT_ID_FILTER:
            return Reduce_setContractIdFilter(state, action);
        case SET_SELECTED_PORTFOLIO:
            return Reduce_setSelectedPortfolio(state, action);
        case SET_EVENT_ID:
            return Reduce_SetEventId(state, action);
        case SET_FETCHING_STATUS:
            return Reduce_SetFetchingStatus(state, action);
        case TOGGLE_MARKER:
            return Reduce_ToggleMarker(state, action);
        case TOGGLE_CLUSTER:
            return Reduce_ToggleCluster(state, action);
        case ADD_POLICY_INSIGHTS:
            return Reduce_AddPolicyInsights(state, action);
        case LOAD_INSIGHTS:
            return Reduce_LoadInsights(state, action);
        case SET_INSIGHTS_GEOJSON:
            return Reduce_SetInsightsGeojson(state, action);
        case REMOVE_INSIGHTS_DATA:
            return Reduce_RemoveInsightsData(state);
        case SET_ASSESSMENT_TYPE:
            return Reduce_SetAssessmentType(state, action);
        case SET_FILTERED_INDICES:
            return Reduce_SetFilteredIndices(state, action);
        case SET_FILTERED_PROPERTIES:
            return Reduce_SetFilteredProperties(state, action);
        case CLEAR_FILTERED_INDICES:
            return Reduce_ClearFilteredIndices(state);
        case SET_SELECTED_PERIL:
            return Reduce_SetSelectedPeril(state, action);
        case SET_REVISIONS:
            return Reduce_SetRevisions(state, action);
        case SET_SELECTED_REVISION_ID:
            return Reduce_SetSelectedRevisionId(state, action);
        case SET_ASSESSMENT_FILTERS:
            return Reduce_SetAssessmentFilters(state, action);
        case SET_ALL_INSIGHTS_STYLES:
            return Reduce_SetAllInsightsStyles(state, action);
        case SET_INSIGHTS_STYLES:
            return Reduce_SetInsightsStyles(state, action);
        case UPDATE_ASSESSMENT_STYLES:
            return Reduce_UpdateAssessmentStyles(state, action);
        default:
            return state;
    }
};

const Reduce_RemoveInsightsData = (state: InsightsState): InsightsState => {
    return {
        ...state,
        insightsType: null,
        insightsData: null,
        locationData: null,
        insightsGeojson: null,
        insightsFilters: {
            damageLabels: [],
            contractIds: [],
        },
        clusterToggle: true,
        assessmentType: "exposure",
    };
};

const Reduce_SetAssessmentFilters = (
    state: InsightsState,
    action: SetAssessmentFiltersAction,
): InsightsState => {
    let newFilterArray: assessmentFilters = state.assessmentFilters;
    let assessmentType: "claims" | "exposure" = action.payload.assessmentType;

    if (!action.payload.assessmentFilter) {
        newFilterArray = {
            ...state.assessmentFilters,
            [assessmentType]: [],
        };
    } else {
        let alreadyBeingFiltered = state.assessmentFilters[
            assessmentType
        ].includes(action.payload.assessmentFilter);

        !alreadyBeingFiltered
            ? (newFilterArray = {
                  ...state.assessmentFilters,
                  [assessmentType]: [
                      ...newFilterArray[assessmentType],
                      action.payload.assessmentFilter,
                  ],
              })
            : (newFilterArray = {
                  ...state.assessmentFilters,
                  [assessmentType]: newFilterArray[assessmentType].filter(
                      (filter) => filter !== action.payload.assessmentFilter,
                  ),
              });
    }

    return {
        ...state,
        assessmentFilters: {
            ...state.assessmentFilters,
            [assessmentType]: newFilterArray[assessmentType],
        },
    };
};

const Reduce_SetInsightsType = (
    state: InsightsState,
    action: SetInsightsTypeAction,
): InsightsState => {
    return { ...state, insightsType: action.payload };
};

const Reduce_SetInsightsLoaded = (
    state: InsightsState,
    action: SetInsightsLoadedAction,
): InsightsState => {
    return { ...state, isInsightsLoaded: action.payload };
};

const Reduce_SetInsightsLayerVisibility = (
    state: InsightsState,
    action: SetInsightsLayerVisibilityAction,
): InsightsState => {
    return {
        ...state,
        insightsVisible: action.payload.visibility === "visible",
    };
};

const Reduce_ToggleInsightsVisibility = (
    state: InsightsState,
): InsightsState => {
    return {
        ...state,
        insightsVisible: !state.insightsVisible,
    };
};

const Reduce_SetInsightsLayerView = (
    state: InsightsState,
    action: SetInsightsLayerViewAction,
): InsightsState => {
    return {
        ...state,
        insightsViewOn: action.payload.viewOn,
    };
};

const Reduce_ClearAllFilters = (state: InsightsState): InsightsState => {
    return {
        ...state,
        insightsFilters: {
            damageLabels: [],
            contractIds: [],
        },
    };
};

const Reduce_toggleDamageLabelFilter = (
    state: InsightsState,
    action: toggleDamageLabelFilterAction,
): InsightsState => {
    let damageLabelFilters = state.insightsFilters.damageLabels.includes(
        action.payload.damageLabel,
    )
        ? state.insightsFilters.damageLabels.filter(
              (item) => item !== action.payload.damageLabel,
          )
        : [...state.insightsFilters.damageLabels, action.payload.damageLabel];

    return {
        ...state,
        insightsFilters: {
            ...state.insightsFilters,
            damageLabels: damageLabelFilters,
        },
    };
};

const Reduce_setSelectedPortfolio = (
    state: InsightsState,
    action: setSelectedPortfolioAction,
): InsightsState => {
    return { 
        ...state, 
        selectedPortfolio: action.payload.selectedPortfolio,
    };
};

const Reduce_SetEventId = (
    state: InsightsState,
    action: SetEventIdAction,
): InsightsState => {
    return { ...state, eventId: action.payload.eventId };
};

const Reduce_SetFetchingStatus = (
    state: InsightsState,
    action: SetFetchingStatusAction,
): InsightsState => {
    return { ...state, isFetchingInsights: action.payload };
};

const Reduce_ToggleMarker = (
    state: InsightsState,
    action: ToggleMarkerAction,
): InsightsState => {
    return {
        ...state,
        marker: action.payload.marker,
    };
};

const Reduce_ToggleCluster = (
    state: InsightsState,
    action: ToggleClusterAction,
): InsightsState => {
    return {
        ...state,
        clusterToggle: !state.clusterToggle,
    };
};

const Reduce_AddPolicyInsights = (
    state: InsightsState,
    action: AddPolicyInsightsAction,
): InsightsState => {
    return {
        ...state,
        policyData: action.payload,
    };
};

const Reduce_LoadInsights = (
    state: InsightsState,
    action: LoadInsightsAction,
): InsightsState => {
    let locationData: Papa.ParseResult<any> | null = null;
    let insightsGeojson: GeoJSON.FeatureCollection | null = null;
    let insightsType: InsightsType = null;

    if (
        action.payload.insightsData &&
        hasNoAffectedProperties(action.payload.insightsData.insights)
    ) {
        insightsType = "noAffectedProperties";
    } else if (!action.payload.insightsData) {
        insightsType = "noInsights";
    } else {
        locationData = action.payload.locationData;
        insightsGeojson = action.payload.insightsGeojson;
    }

    const hasClaims = locationData?.meta.fields?.includes(
        "Claims Layer Assessment",
    );

    const assessmentType = hasClaims ? "claims" : "exposure";

    const scaleVersion =
        assessmentType === "exposure"
            ? action.payload.insightsData?.description
                  .exposure_peril_scale_version ?? 1
            : action.payload.insightsData?.description
                  .claims_peril_scale_version ?? 1;

    const claimsInsightsStyle = state.allInsightsStyles.filter(
        (style) =>
            style.name === `${state.selectedPeril}_claims_v${scaleVersion}`,
    )[0];

    const exposureInsightsStyle = state.allInsightsStyles.filter(
        (style) =>
            style.name === `${state.selectedPeril}_exposure_v${scaleVersion}`,
    )[0];

    return {
        ...state,
        insightsType,
        locationData,
        insightsGeojson,
        isInsightsLoaded: true,
        assessmentType: assessmentType,
        insightsStyles: {
            claims: claimsInsightsStyle,
            exposure: exposureInsightsStyle,
        },
        insightsData: action.payload.insightsData,
        eventId: action.payload.eventId,
    };
};

const Reduce_SetInsightsGeojson = (
    state: InsightsState,
    action: SetInsightsGeojsonAction,
): InsightsState => {
    return {
        ...state,
        insightsGeojson: action.payload,
    };
};

const Reduce_SetAssessmentType = (
    state: InsightsState,
    action: SetAssessmentTypeAction,
): InsightsState => {
    return {
        ...state,
        assessmentType: action.payload.assessmentType,
    };
};

const Reduce_UpdateAssessmentStyles = (
    state: InsightsState,
    action: UpdateAssessmentStylesAction,
): InsightsState => {
    const scaleVersion =
        action.payload.assessmentType === "exposure"
            ? action.payload.insightsData?.description
                  .exposure_peril_scale_version ?? 1
            : action.payload.insightsData?.description
                  .claims_peril_scale_version ?? 1;

    const claimsInsightsStyle = state.allInsightsStyles.filter(
        (style) =>
            style.name === `${state.selectedPeril}_claims_v${scaleVersion}`,
    )[0];

    const exposureInsightsStyle = state.allInsightsStyles.filter(
        (style) =>
            style.name === `${state.selectedPeril}_exposure_v${scaleVersion}`,
    )[0];

    return {
        ...state,
        insightsStyles: {
            claims: claimsInsightsStyle,
            exposure: exposureInsightsStyle,
        },
    };
};

const Reduce_SetFilteredProperties = (
    state: InsightsState,
    action: SetFilteredPropertiesAction,
): InsightsState => {
    let insightsGeojson = filterGeojsonByProperties(
        geoJSONFromPapaparse(state.locationData!),
        [
            {
                property: action.payload.property,
                values: action.payload.values,
            },
        ],
    );

    return {
        ...state,
        insightsGeojson,
    };
};

const Reduce_SetFilteredIndices = (
    state: InsightsState,
    action: SetFilteredIndicesAction,
): InsightsState => {
    // Rebuilds the insights geojson from the supplied indices.
    // This avoids having to keep multiple, potentially large GeoJSON objects in memory.

    let insightsGeojson = filterGeojsonByIndex(
        geoJSONFromPapaparse(state.locationData!),
        action.payload.indices,
    );

    return {
        ...state,
        insightsGeojson,
    };
};

const Reduce_setContractIdFilter = (
    state: InsightsState,
    action: setContractIdFilterAction,
): InsightsState => {
    return {
        ...state,
        insightsFilters: {
            ...state.insightsFilters,
            contractIds: action.payload.contractIds,
        },
    };
};


const Reduce_ClearFilteredIndices = (state: InsightsState): InsightsState => {
    // Rebuilds the insights geojson without any filtered indices.
    // This avoids having to keep multiple, potentially large GeoJSON objects in memory.
    return {
        ...state,
        insightsGeojson: geoJSONFromPapaparse(state.locationData!),
    };
};

const Reduce_SetSelectedPeril = (
    state: InsightsState,
    action: SetSelectedPerilAction,
): InsightsState => {
    return {
        ...state,
        selectedPeril: action.payload.selectedPeril,
        locationData: null,
        isInsightsLoaded: false,
    };
};

const Reduce_SetRevisions = (
    state: InsightsState,
    action: SetRevisionsAction,
): InsightsState => {
    if (action.payload.length !== 0) {
        // Only use currently selected portfolio if new revisions are for current portfolio
        const peril = action.payload[0]!.path.includes(state?.selectedPortfolio?.id!) ? state.selectedPeril : action.payload[0].peril;
        // If selected peril in state, set revisions ensuring selected peril is maintained as first
        const revisionsBySelectedPeril = formatExposureClaims(
            action.payload.filter(
                (revision) => revision.peril === peril,
            ),
        );
        // If there's a currently selectedRevision, and a peril in the revisions
        // that matches our currently selected peril, use these
        const currentlySelectedPeril = state.selectedPeril;
        const revisionMatchingSelectedPeril = revisionsBySelectedPeril.find(
            (revision) => revision.peril === currentlySelectedPeril,
        );
        if (
            currentlySelectedPeril &&
            Boolean(revisionMatchingSelectedPeril)
        ) {
            return {
                ...state,
                selectedRevisionId: revisionMatchingSelectedPeril!.id,
                revisions: [...action.payload],
            };
        }
        // Otherwise just selected the first
        return {
            ...state,
            selectedPeril: peril,
            selectedRevisionId: action.payload[0].id,
            revisions: [...action.payload],
        };
    } else {
        return {
            ...state,
            selectedPeril: null,
            selectedRevisionId: "",
            revisions: [],
        };
    }
};

const Reduce_SetSelectedRevisionId = (
    state: InsightsState,
    action: SetSelectedRevisionIdAction,
): InsightsState => {
    return {
        ...state,
        selectedRevisionId: action.payload,
    };
};

const Reduce_SetAllInsightsStyles = (
    state: InsightsState,
    action: SetAllInsightsStylesAction,
): InsightsState => {
    return {
        ...state,
        allInsightsStyles: action.payload,
    };
};

const Reduce_SetInsightsStyles = (
    state: InsightsState,
    action: SetInsightsStylesAction,
): InsightsState => {
    return {
        ...state,
        insightsStyles: action.payload,
    };
};
