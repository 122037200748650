import React, { createContext, FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { mdiClose, mdiMenu } from "@mdi/js";
import { RootState } from "../../../store/store";
import { getStoreAtNamespaceKey } from "../../../store/storeSelectors";
import AccountDropdown from "./AccountDropdown/AccountDropdown";
import InitialsIcon from "../../_Library/InitialsIcon/InitialsIcon";
import HelpDropdown from "./HelpDropdown/HelpDropdown";

import GEOLogo from "/src/assets/images/logos/geo_logo_no_butterfly.svg";
import ButterflyLogo from "/src/assets/images/logos/butterfly_logo.svg";
import classes from "./NavBar.module.css";
import EventDropdown from "./EventDropdown/EventDropdown";
import { useSelector } from "react-redux";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { Button } from "@mantine/core";

type NavDropdown = "event" | "investigations" | "help" | "account";
type DropdownVisibility = { [key in NavDropdown]: boolean };

export const DropdownVisibilityContext = createContext<{
    state: DropdownVisibility;
    setState: React.Dispatch<React.SetStateAction<DropdownVisibility>>;
}>({
    state: {
        account: false,
        investigations: false,
        event: false,
        help: false,
    },
    setState: () => {},
});

interface NavBarProps {
    isSignedIn?: boolean;
}

const NavBar: FC<NavBarProps> = ({ isSignedIn = true }) => {
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );
    const { trackUserEvent } = useAnalytics();
    const [dropdownVisibility, setDropdownVisibility] =
        useState<DropdownVisibility>({
            account: false,
            investigations: false,
            event: false,
            help: false,
        });

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [helpMenuVisible, setHelpMenuVisible] = useState(false);
    const [accountMenuVisible, setAccountMenuVisible] = useState(false);


    const handleBurgerMenuClick = () => setMobileMenuOpen(!mobileMenuOpen);

    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (
                (e.target as HTMLElement)?.closest(`.${classes.NavBar}`) ===
                null
            ) {
                setDropdownVisibility({
                    account: false,
                    investigations: false,
                    event: false,
                    help: false,
                });
            }
        };

        document.addEventListener("click", checkIfClickedOutside);
        window.addEventListener("click", checkIfClickedOutside);

        return () => {
            document.removeEventListener("click", checkIfClickedOutside);
            window.addEventListener("click", checkIfClickedOutside);
        };
    }, []);

    const closeDropdown = () => {
        setDropdownVisibility({
            account: false,
            investigations: false,
            event: false,
            help: false,
        });
    };

    const makeIconLink = (
        label: string,
        linkUrl: string,
        divClass: string,
        iconClass: string,
        onClick?: () => void,
    ) => {
        // For the help button, this should technically be a non a-tag,
        // however as a hotfix (86bxn7z2z) do not add the "to" prop
        // for help, as it will clear query params and result in side effects
        return (
            <div
                data-tip={label}
                data-for={label}
                className={divClass}
                onClick={onClick}
            >
                {label === "Help" ? (
                    <button className={classes.NavItem}>
                        <span className={cx(classes.GradientBG, iconClass)}>
                            <Icon
                                className={cx({
                                    [classes.Hidden]: mobileMenuOpen,
                                })}
                                path={""}
                                size={1.25}
                            />
                        </span>
                        <span className={classes.NewIcon} />
                    </button>
                ) : (
                    <Link className={classes.NavItem} to={linkUrl}>
                        <span className={cx(classes.GradientBG, iconClass)}>
                            <Icon
                                className={cx({
                                    [classes.Hidden]: mobileMenuOpen,
                                })}
                                size={1.25}
                                path={""}
                            />
                        </span>
                    </Link>
                )}
                <ReactTooltip effect="solid" place="bottom" id={label} />
            </div>
        );
    };
    useEffect(() => {
        if (dropdownVisibility.help) {
            setHelpMenuVisible(true);
        }
        if (dropdownVisibility.account) {
            setAccountMenuVisible(true);
        }
    }, [dropdownVisibility.help, dropdownVisibility.account]);
    return (
        <DropdownVisibilityContext.Provider
            value={{
                state: dropdownVisibility,
                setState: setDropdownVisibility,
            }}
        >
            <nav className={classes.NavBar}>
                <div
                    className={classes.LogoWrapper}
                    onClick={() => {
                        trackUserEvent({
                            name: "home_navbar_clicked",
                        });
                    }}
                >
                    <Link
                        to="/"
                        className={cx(classes.LogoWrapper, classes.Mobile)}
                    >
                        <img alt="GEO Logo" src={ButterflyLogo} />
                    </Link>
                    <Link to="/">
                        <img alt="GEO Logo" src={GEOLogo} />
                    </Link>
                </div>
                <div
                    className={classes.NavHamburger}
                    onClick={handleBurgerMenuClick}
                >
                    <Icon
                        className={cx({ [classes.Hidden]: mobileMenuOpen })}
                        path={mdiMenu}
                    />
                    <Icon
                        className={cx({ [classes.Hidden]: !mobileMenuOpen })}
                        path={mdiClose}
                    />
                </div>
                {isSignedIn ? (
                    <div
                        className={cx(classes.LinksContainer, {
                            [classes.MobileNavigation]: mobileMenuOpen,
                        })}
                    >
                        <div className={classes.NavLinks}>
                            <EventDropdown />
                        </div>
                        <div className={classes.AccountLinks}>
                            {user?.is_admin &&
                                makeIconLink(
                                    "Admin",
                                    "/admin/events",
                                    classes.IconButton,
                                    classes.CogMask,
                                )}
                            {makeIconLink(
                                "Help",
                                "",
                                cx(classes.IconButton, {
                                    [classes.HelpButtonActive]:
                                        dropdownVisibility.help,
                                }),
                                classes.HelpMask,
                                () => {
                                    trackUserEvent({
                                        name: "help_navbar_clicked",
                                    });
                                    setHelpMenuVisible(!helpMenuVisible);
                                    setDropdownVisibility({
                                        account: false,
                                        investigations: false,
                                        event: false,
                                        help: !dropdownVisibility.help,
                                    });
                                },
                            )}
                            {dropdownVisibility.help && (
                                <div
                                    className={cx({
                                        [classes.Hidden]: !helpMenuVisible,
                                    })}
                                >
                                    <HelpDropdown
                                        closeDropdown={closeDropdown}
                                        setHelpMenuVisible={(setHelpMenuVisible)}
                                    />
                                </div>
                            )}
                            <div
                                className={cx(classes.AccountDropdownButton, {
                                    [classes.ActiveDropdown]:
                                        dropdownVisibility.account,
                                })}
                                onClick={() => {
                                    if (!dropdownVisibility.account) {
                                        trackUserEvent({
                                            name: "profile_navbar_clicked",
                                        });
                                    }
                                    setAccountMenuVisible(!accountMenuVisible);
                                    setDropdownVisibility({
                                        account: !dropdownVisibility.account,
                                        investigations: false,
                                        event: false,
                                        help: false,
                                    });
                                }}
                            >
                                <InitialsIcon
                                    firstName={user?.first_name}
                                    lastName={user?.last_name}
                                />
                            </div>
                            {dropdownVisibility.account && (
                                <div
                                    className={cx({
                                        [classes.Hidden]: !accountMenuVisible,
                                    })}
                                >
                                    <AccountDropdown 
                                        closeDropdown={closeDropdown}
                                        setAccountMenuVisible={setAccountMenuVisible}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.SignInButton}>
                        <Button variant="confirm">
                            <Link to="/login">Sign in</Link>
                        </Button>
                    </div>
                )}
            </nav>
        </DropdownVisibilityContext.Provider>
    );
};

export default NavBar;
