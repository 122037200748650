/**
 * Holds the tab components and displays the currently active one.
 * All tabs are rendered to the tree (but not displayed) even when not active as we need the geocoder tab ref to stay alive for its portal.
 */

import React, { Component, ComponentClass, FunctionComponent } from "react";
import cx from "classnames";

import ReactTooltip from "react-tooltip";

import classes from "./TabbedMenu.module.css";
import Icon from "@mdi/react";
import { MenuConfig, TabType } from "store/report/reportTypes";
import { connect, ConnectedComponent } from "react-redux";
import { toggleMenu } from "store/report/reportActions";
import { bindActionCreators, Dispatch } from "redux";
import SectionRow from "../SectionRow/SectionRow";
import {
    mdiInformationOutline,
    mdiLayersTriple,
    mdiMagnify,
    mdiMenuUp,
} from "@mdi/js";
import withAnalytics, { withAnalyticsProps } from "../HOC/withAnalytics";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";

interface OwnProps {
    tabs: TabConfig;
}

interface StateProps {
    menuConfig: MenuConfig;
    activeTab: TabType;
}

interface DispatchProps {
    toggleMenu: (open: boolean) => void;
    setActiveTab: (tab: TabType) => object;
}

type TabbedMenuProps = OwnProps & StateProps & DispatchProps & withAnalyticsProps;


export type TabConfig = {
    [key in TabType]?: {
        name: string; // used for title and tooltip
        iconComponent: JSX.Element;
        component:
            | ConnectedComponent<any, any>
            | FunctionComponent
            | ComponentClass; // child component used for tab
        onNavigate?: () => void;
    };
};

export type ButtonConfig = {
    [key: string]: {
        name: string;
        iconComponent: JSX.Element;
        function: () => void;
    };
};

class TabbedMenu extends Component<TabbedMenuProps> {

    setActiveTab = (tabName: TabType) => {
        let tabConfig = this.props.tabs[tabName];
        if (tabConfig?.onNavigate) {
            tabConfig.onNavigate();
        }
        this.props.setActiveTab(tabName);
    };

    toggleTab = () => {

        if (this.props.menuConfig.menuHidden) {
            this.props.analytics.trackUserEventWithCurrentEvent({
                name: "data_layer_collapsed",
            })
        } else {
            this.props.analytics.trackUserEventWithCurrentEvent({
                name: "data_layer_expanded",
            })
        }
        this.props.toggleMenu(!this.props.menuConfig.menuHidden);
        
    };

    // Render all tab panes into tab container body
    addTabPanes() {
        let activeTab = this.props.activeTab;
        return Object.keys(this.props.tabs).map((tabName) => {
            const tabOptions = this.props.tabs[tabName as TabType]!;
            return (
                <div
                    id={"tourid_" + tabName}
                    key={tabName + "Key"}
                    className={cx(classes.TabPane, {
                        [classes.TabPaneActive]: tabName === activeTab,
                    })}
                >
                    <div className={classes.TabBody}>
                        {React.createElement(
                            tabOptions.component,
                            // @ts-ignore
                            { toggleMenu: this.toggleTab },
                        )}
                    </div>
                </div>
            );
        });
    }

    render() {

        const tourId = "TabsSideDrawer";
        return (
            <div
                className={cx(classes.Container, {
                    [classes.Hide]: this.props.menuConfig.menuHidden,
                })}
                id={tourId}
            >
                <div className={classes.TabsContainer}>
                    <SectionRow
                        items={[
                            {
                                label: "Search",
                                iconComponent: (
                                    <Icon path={mdiMagnify} size={1.5} />
                                ),
                                onClick: () => this.setActiveTab("Geocoder"),
                                active: this.props.activeTab === "Geocoder",
                                id: "tourid_LayerSearch",
                            },
                            {
                                label: "Layers",
                                iconComponent: (
                                    <Icon path={mdiLayersTriple} size={1.5} />
                                ),
                                onClick: () => this.setActiveTab("Layer"),
                                active: this.props.activeTab === "Layer",
                                id: "tourid_Layers",
                            },
                            {
                                label: "Details",
                                iconComponent: (
                                    <Icon
                                        path={mdiInformationOutline}
                                        size={1.5}
                                    />
                                ),
                                onClick: () => this.setActiveTab("Info"),
                                active: this.props.activeTab === "Info",
                                id: "tourid_LayerInfo",
                            },
                        ]}
                    />
                </div>
                <div
                    className={classes.TabPanes}
                    id={"TabBody"}
                >
                    <div
                        data-tip={
                            !this.props.menuConfig.menuHidden
                                ? "Close side panel"
                                : "Open side panel"
                        }
                        data-for={"TabContainerToolTip"}
                        className={cx(classes.TabMenuToggle, {
                            [classes.Closed]: this.props.menuConfig.menuHidden
                        })}
                        onClick={() => {
                            this.toggleTab();
                        }}
                    >
                        <Icon path={mdiMenuUp} size={1.25} />
                        <ReactTooltip
                            id={"TabContainerToolTip"}
                            place={"left"}
                            effect={"solid"}
                        />
                    </div>
                    {this.addTabPanes()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        menuConfig: getStoreAtNamespaceKey(state, "report").menuConfig,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleMenu: bindActionCreators(toggleMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAnalytics(TabbedMenu));
 