import {
    ADD_CUSTOM_LAYER,
    AddCustomLayerAction,
    BasemapType,
    ConfigSource,
    MapActionTypes,
    ReportState,
    REMOVE_CUSTOM_LAYER,
    RemoveCustomLayerAction,
    REPLACE_INSIGHTS_LAYER_DATA,
    ReplaceInsightsLayerDataAction,
    SET_ACTIVE_TAB,
    SET_BASEMAP,
    SET_CLICKED_FEATURE_PROPERTIES,
    SET_EVENT_TYPE,
    SET_GROUP_VISIBILITY,
    SET_HIGHLIGHTED_LAYER,
    SET_INTERACTION_MODE,
    SET_LAYER_FILTER,
    SET_LAYER_VIEW,
    SET_LAYER_VISIBILITY,
    SET_LEGEND_POPUP,
    SET_MAP_MARKER_LOCATION,
    SET_MAP_TYPE,
    SET_MOUSE_POSITION,
    SET_VIEWPORT,
    SetActiveTabAction,
    SetBasemapAction,
    SetClickedFeaturePropertiesAction,
    SetEventTypeAction,
    SetGroupVisibilityAction,
    SetInteractionModeAction,
    SetLayerFilterAction,
    SetLayerViewAction,
    SetLayerVisibilityAction,
    SetLegendPopupAction,
    SetMapMarkerLocationAction,
    SetMapTypeAction,
    SetMousePositionAction,
    SetSelectedLayerAction,
    SetViewportAction,
    TOGGLE_LOCATION_LABELS,
    TOGGLE_MENU,
    ToggleLocationLabelsAction,
    ToggleMenuAction,
    SET_CURRENT_DATE,
    SET_DASHBOARD_VIEW,
    SetDashboardViewAction,
    SetCurrentDateAction,
    SET_LAYERS_CONFIG,
    SetLayersConfigAction,
    SET_REPORT_IS_PREVIEW,
    SetReportIsPreviewAction,
} from "./reportTypes";
import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";
import { generateHash } from "../../utils/Maths";
import { Reducer } from "redux";
import _ from "lodash";
import { replaceSearchParams } from "utils/URLs";
import { bbox } from "@turf/turf";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";

const initState: ReportState = {
    viewport: {
        latitude: 0,
        longitude: 0,
        zoom: 0,
    },
    layersConfig: {
        mapType: "single",
        sources: {},
        menuIndex: [],
    },
    reportTexts: [],
    reportDownloads: [],
    menuConfig: {
        menuHidden: false,
        activeTab: "Layer",
    },
    highlightedLayer: null,
    legendPopup: null,
    clickedFeatureProperties: {},
    mousePosition: {
        lat: 0,
        lng: 0,
    },
    interactiveLayerIds: [],
    basemaps: ["satellite", "light"],
    basemapOptions: {
        dark: "mike-mis/cjz9un4sf01c91cp4qtyxs2vg",
        light: "mike-mis/ckcfxe3fg0krb1iqrcm0fiyy1",
        satellite: "mike-mis/ckebg0mb317xs1aqlqcbspm7y",
    },
    interactionMode: "standard",
    eventType: "flood",
    mapMarkerLocation: null,
    locationLabels: true,
    mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN!,
    dashboardView: "report",
    layerFilters: {},
    isPreview: false,
};

// HELPERS
const findGroupById = (
    groupId: string,
    parentIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
): ConfigMenuGroup | undefined => {
    return findGroupBy("id", groupId, parentIndex);
};

const findGroupBy = (
    property: keyof ConfigMenuGroup,
    searchTerm: string,
    parentIndex: (ConfigMenuGroup | ConfigMenuLayer)[],
): ConfigMenuGroup | undefined => {
    let foundItem;

    let recursiveLookup = (index: (ConfigMenuGroup | ConfigMenuLayer)[]) => {
        for (let i = 0; i < index.length; i++) {
            let element = index[i];
            if (element.type === "group") {
                if (element[property] === searchTerm) {
                    foundItem = element;
                    break;
                }
                recursiveLookup(element.children);
            }
        }
    };

    recursiveLookup(parentIndex);

    return foundItem;
};

export const reportReducer: Reducer<ReportState, MapActionTypes> = (
    state = initState,
    action,
): ReportState => {
    switch (action.type) {
        case SET_LAYERS_CONFIG:
            return Reduce_SetLayersConfig(state, action);
        case SET_MOUSE_POSITION:
            return Reduce_SetMousePosition(state, action);
        case SET_VIEWPORT:
            return Reduce_SetViewport(state, action);
        case SET_LAYER_VISIBILITY:
            return Reduce_SetLayerVisibility(state, action);
        case SET_GROUP_VISIBILITY:
            return Reduce_SetGroupVisibility(state, action);
        case TOGGLE_MENU:
            return Reduce_ToggleMenu(state, action);
        case SET_MAP_TYPE:
            return Reduce_SetMapType(state, action);
        case SET_ACTIVE_TAB:
            return Reduce_SetActiveTab(state, action);
        case SET_HIGHLIGHTED_LAYER:
            return Reduce_SetSelectedLayer(state, action);
        case SET_LEGEND_POPUP:
            return Reduce_SetLegendPopup(state, action);
        case SET_CLICKED_FEATURE_PROPERTIES:
            return Reduce_SetClickedFeatureProperties(state, action);
        case SET_BASEMAP:
            return Reduce_SetBasemap(state, action);
        case SET_INTERACTION_MODE:
            return Reduce_SetInteractionMode(state, action);
        case ADD_CUSTOM_LAYER:
            return Reduce_AddCustomLayer(state, action);
        case REMOVE_CUSTOM_LAYER:
            return Reduce_RemoveCustomLayer(state, action);
        case REPLACE_INSIGHTS_LAYER_DATA:
            return Reduce_ReplaceInsightsLayerData(state, action);
        case SET_LAYER_VIEW:
            return Reduce_SetLayerView(state, action);
        case SET_LAYER_FILTER:
            return Reduce_SetLayerFilter(state, action);
        case SET_EVENT_TYPE:
            return Reduce_SetEventType(state, action);
        case SET_MAP_MARKER_LOCATION:
            return Reduce_SetMapMarkerLocation(state, action);
        case TOGGLE_LOCATION_LABELS:
            return Reduce_ToggleLocationLabels(state, action);
        case SET_CURRENT_DATE:
            return Reduce_setCurrentDate(state, action);
        case SET_DASHBOARD_VIEW:
            return Reduce_setDashboardView(state, action);
        case SET_REPORT_IS_PREVIEW:
            return Reduce_setReportIsPreview(state, action);
        default:
            return state;
    }
};

const Reduce_SetLayersConfig = (
    state: ReportState,
    action: SetLayersConfigAction,
): ReportState => {
    return {
        ...state,
        layersConfig: action.payload,
    };
};

const Reduce_setReportIsPreview = (
    state: ReportState,
    action: SetReportIsPreviewAction,
): ReportState => {
    return {
        ...state,
        isPreview: action.payload,
    };
};

const Reduce_setDashboardView = (
    state: ReportState,
    action: SetDashboardViewAction,
): ReportState => {
    if (action.payload.history) {
        action.payload.history.push({
            search: replaceSearchParams(
                action.payload.history.location.search,
                {
                    page: action.payload.view,
                },
            ),
        });
    }

    return { ...state, dashboardView: action.payload.view };
};

const Reduce_setCurrentDate = (
    state: ReportState,
    action: SetCurrentDateAction,
): ReportState => {
    return {
        ...state,
        currentTimelineDate: action.payload,
    };
};

const Reduce_SetEventType = (
    state: ReportState,
    action: SetEventTypeAction,
): ReportState => {
    return { ...state, eventType: action.payload };
};

const Reduce_SetMapMarkerLocation = (
    state: ReportState,
    action: SetMapMarkerLocationAction,
): ReportState => {
    return { ...state, mapMarkerLocation: action.payload };
};

const Reduce_SetLayerView = (
    state: ReportState,
    action: SetLayerViewAction,
): ReportState => {
    let sourceName = action.payload.sourceName;
    let viewOn = action.payload.viewOn;
    let mapConfig = state.layersConfig;
    let sources = mapConfig.sources;
    let layer = sources[sourceName];
    layer.viewOn = viewOn;
    return { ...state, layersConfig: { ...mapConfig } };
};

const Reduce_AddCustomLayer = (
    state: ReportState,
    action: AddCustomLayerAction,
): ReportState => {
    // Add custom layer to mapconfig.source
    let customPaint;
    switch (action.payload.layerType) {
        case "circle":
            customPaint = {
                "circle-color": action.payload.layerColor,
                "circle-radius": 5,
                "circle-stroke-color": action.payload.layerStroke ?? "white",
                "circle-stroke-width": 2,
            };
            break;
        case "line":
            customPaint = {
                "line-color": action.payload.layerColor,
                "line-width": 3,
            };
            break;
        case "fill":
            customPaint = {
                "fill-color": action.payload.layerColor,
                "fill-opacity": 0.75,
            };
            break;
        default:
            customPaint = {};
    }

    let geojson = action.payload.geojsonData;

    geojson.features.forEach((feature, index) => {
        feature.properties!.feature_id = String(index);
    });

    const boundingBox = bbox(geojson) as BBox2d;

    const geoJsonSource: ConfigSource = {
        layerName: action.payload.layerName,
        layerType: action.payload.layerType,
        layout: {
            visibility: "visible",
        },
        paint: customPaint,
        viewOn: "both",
        // @ts-ignore
        complexPaintProperties: ["circle-color"],
        interactive: true,
        type: "geojson",
        data: geojson,
        actions: {
            zoomTo: {
                bbox: boundingBox,
            },
        },
    };

    let sources = state.layersConfig.sources;
    const sourceName =
        action.payload.sourceName ?? action.payload.layerName + "-source";
    sources[sourceName] = geoJsonSource;

    // Add custom layer to mapconfig.menuIndex
    let customLayer: ConfigMenuLayer = {
        id: generateHash(),
        type: "layer",
        layerName: action.payload.layerName,
        layerSource: sourceName,
    };

    const groupName = action.payload.groupName ?? "Your Custom Data";

    let customCategory = state.layersConfig.menuIndex.find(
        (category) =>
            category.type === "group" && category.groupName === groupName,
    ) as ConfigMenuGroup | undefined;

    if (!customCategory) {
        state.layersConfig.menuIndex.unshift({
            id: generateHash(),
            type: "group",
            groupName: groupName,
            children: [customLayer],
        });
    } else {
        customCategory.children.unshift(customLayer);
    }

    return {
        ...state,
        interactiveLayerIds: [
            ...state.interactiveLayerIds,
            action.payload.layerName,
        ],
        layersConfig: {
            ...state.layersConfig,
            sources,
            menuIndex: state.layersConfig.menuIndex,
        },
    };
};

const Reduce_RemoveCustomLayer = (
    state: ReportState,
    action: RemoveCustomLayerAction,
): ReportState => {
    let recursiveLookup = (index: (ConfigMenuGroup | ConfigMenuLayer)[]) => {
        for (let i = 0; i < index.length; i++) {
            let element = index[i];
            if (element.type === "layer") {
                if (element.layerName === action.payload.layerName) {
                    delete state.layersConfig.sources[element.layerSource];
                    index.splice(i, 1);
                }
            } else {
                recursiveLookup(element.children);
                if (element.children.length === 0) {
                    index.splice(i--, 1);
                }
            }
        }
    };

    recursiveLookup(state.layersConfig.menuIndex);

    return { ...state, layersConfig: { ...state.layersConfig } };
};

const Reduce_ReplaceInsightsLayerData = (
    state: ReportState,
    action: ReplaceInsightsLayerDataAction,
): ReportState => {
    state.layersConfig.sources[action.payload.sourceName].data =
        action.payload.data;
    return {
        ...state,
        layersConfig: {
            ...state.layersConfig,
            menuIndex: [...state.layersConfig.menuIndex],
            sources: { ...state.layersConfig.sources },
        },
    };
};

const Reduce_SetInteractionMode = (
    state: ReportState,
    action: SetInteractionModeAction,
): ReportState => {
    // Toggling interaction mode when already in that mode will return to standard mode
    if (state.interactionMode === action.payload) {
        return { ...state, interactionMode: "standard" };
    }
    return { ...state, interactionMode: action.payload };
};

const Reduce_SetBasemap = (
    state: ReportState,
    action: SetBasemapAction,
): ReportState => {
    let basemaps: [BasemapType, BasemapType] = [...state.basemaps];
    basemaps[action.payload.mapIndex] = action.payload.basemap;
    return { ...state, basemaps };
};

const Reduce_SetMousePosition = (
    state: ReportState,
    action: SetMousePositionAction,
): ReportState => {
    return {
        ...state,
        mousePosition: action.payload,
    };
};

const Reduce_SetViewport = (
    state: ReportState,
    action: SetViewportAction,
): ReportState => {
    return {
        ...state,
        viewport: { ...action.payload },
    };
};

const Reduce_SetLayerVisibility = (
    state: ReportState,
    action: SetLayerVisibilityAction,
): ReportState => {
    let sourceName = action.payload.sourceName;
    let visibility = action.payload.visibility;
    let mapConfig = state.layersConfig;
    let sources = mapConfig.sources;
    let layer;

    if (sources[sourceName]) {
        layer = sources[sourceName];
        layer.layout.visibility = visibility;
    }
    mapConfig.menuIndex = _.cloneDeep(mapConfig.menuIndex);

    return { ...state, layersConfig: { ...mapConfig } };
};

const Reduce_SetGroupVisibility = (
    state: ReportState,
    action: SetGroupVisibilityAction,
): ReportState => {
    let foundGroup: ConfigMenuGroup | undefined = findGroupById(
        action.payload.groupId,
        state.layersConfig.menuIndex,
    );
    if (foundGroup) {
        const layersConfig = state.layersConfig;
        const setGroupVisibility = (
            index: (ConfigMenuGroup | ConfigMenuLayer)[],
        ) => {
            index.forEach((element) => {
                if (element.type === "layer") {
                    state.layersConfig.sources[
                        element.layerSource
                    ].layout.visibility = action.payload.visibility;
                } else {
                    setGroupVisibility(element.children);
                    element.id = generateHash();
                }
            });
        };
        setGroupVisibility(foundGroup.children);

        let menuIndex = _.cloneDeep(layersConfig.menuIndex);

        return { ...state, layersConfig: { ...layersConfig, menuIndex } };
    }
    return state;
};

const Reduce_SetMapType = (
    state: ReportState,
    action: SetMapTypeAction,
): ReportState => {
    return {
        ...state,
        layersConfig: {
            ...state.layersConfig,
            mapType: action.payload,
        },
    };
};

const Reduce_SetSelectedLayer = (
    state: ReportState,
    action: SetSelectedLayerAction,
): ReportState => {
    return { ...state, highlightedLayer: action.payload };
};

const Reduce_SetLegendPopup = (
    state: ReportState,
    action: SetLegendPopupAction,
): ReportState => {
    return { ...state, legendPopup: action.payload };
};

const Reduce_SetClickedFeatureProperties = (
    state: ReportState,
    action: SetClickedFeaturePropertiesAction,
): ReportState => {
    return { ...state, clickedFeatureProperties: action.payload };
};

const Reduce_ToggleMenu = (
    state: ReportState,
    action: ToggleMenuAction,
): ReportState => {
    return {
        ...state,
        menuConfig: {
            ...state.menuConfig,
            menuHidden: !state.menuConfig.menuHidden,
        },
    };
};

const Reduce_SetActiveTab = (
    state: ReportState,
    action: SetActiveTabAction,
): ReportState => {
    return {
        ...state,
        menuConfig: {
            ...state.menuConfig,
            menuHidden: false,
            activeTab: action.payload,
        },
    };
};

const Reduce_SetLayerFilter = (
    state: ReportState,
    action: SetLayerFilterAction,
): ReportState => {
    return {
        ...state,
        layerFilters: {
            ...state.layerFilters,
            [action.payload.sourceName]: action.payload.layerFilter,
        },
    };
};

const Reduce_ToggleLocationLabels = (
    state: ReportState,
    action: ToggleLocationLabelsAction,
) => {
    return { ...state, locationLabels: action.payload };
};
