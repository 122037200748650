import React, { useEffect, useState } from "react";
import classes from "../AccountModal.module.css";
import { useOktaAuth } from "@okta/okta-react";
import { AccessManagementUserBase } from "types/auth";
import { useUserMeAccessQuery } from "crud/me_access";
import ThemeToggle from "components/_Library/ThemeToggle/ThemeToggle";
import Button from "components/_Library/Button/Button";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { generateAPIKey, getAPIKey, revokeAPIKey } from "crud/securityCRUD";
import CopyAlertButton from "components/_Library/CopyAlertButton/CopyAlertButton";

interface OwnProps {
    user: AccessManagementUserBase | undefined
}

type SettingsTabProps = OwnProps;

const SettingsTab: React.FC<SettingsTabProps> = ({ user }) => {

    const { oktaAuth } = useOktaAuth();
    const { data } = useUserMeAccessQuery();
 
    const [apiKey, setApiKey] = useState("")

    useEffect(() => {
        getUserAPIKey()

        //(This is being used as componentDidMount, doesn't need dependencies.)
        // eslint-disable-next-line 
    }, [])

    const getUserAPIKey = async () => {
        const token = oktaAuth.getAccessToken();
        if (token) {
            let res = await getAPIKey(token);
            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey(res.data.api_key);
            }
        }
    };

    const requestAPIKey = async () => {
        let token = oktaAuth.getAccessToken();
        if (token) {
            let res = await generateAPIKey(token);
            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey(res.data.api_key);
            }
        }
    };

    const removeApiKey = async () => {
        let token = oktaAuth.getAccessToken();
        if (token) {
            let res = await revokeAPIKey(token, apiKey);

            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey("");
            }
        }
    };

    return (
        <>
            {data?.has_api_access && 
                <div className={classes.ContentContainer}>
                    <p className={classes.ContainerHeader}>Developer Settings</p>
                    <p className={classes.Subheader}>API Key</p>
                    <div className={classes.ApiKeyContainer}>
                        
                        <div className={classes.ApiKeyContainer}>
                            {apiKey.length ?
                                <>
                                    <p className={classes.ApiKey}>{apiKey}</p>
                                    <CopyAlertButton 
                                        value={apiKey}
                                        alertMessage="API key copied to clipboard" 
                                        buttonClassName={classes.CopyButton}
                                    />
                                </> : null
                            }
                        </div>                                   
                        {
                            apiKey.length ? 
                                <Button size={{width: "20rem"}} onClick={removeApiKey}>Revoke Key</Button> : 
                                <Button size={{width: "20rem"}} onClick={requestAPIKey}>Request Key</Button>
                        }
                    </div>
                </div>
            }

            <div className={classes.ContentContainer}>
                <p className={classes.ContainerHeader}>Display</p>
                <ThemeToggle />
            </div>
        </>
    )
};

export default SettingsTab;