import React, { Component, ReactNode, RefObject } from "react";
import { InteractionModeType } from "store/report/reportTypes";
import { connect } from "react-redux";
import { RootState } from "store/store";
import InteractionModeStandard from "../InteractionModes/InteractionModeStandard/InteractionModeStandard";
import InteractionModeMeasureDistance from "../InteractionModes/InteractionModeMeasureDistance/InteractionModeMeasureDistance";
import InteractionModeMeasureArea from "../InteractionModes/InteractionModeMeasureArea/InteractionModeMeasureArea";
import { getStoreAtNamespaceKey } from "../../../../../../../../store/storeSelectors";
import { MapLayerMouseEvent, MapRef } from "react-map-gl";

export interface InteractionModeMapProps {
    interactions: MapInteractions;
    additionalInteractiveLayerIds: string[];
    layers: ReactNode;
    popup: ReactNode;
    contextMenu: ReactNode;
    controls: { [key: string]: ReactNode };
}

export interface OwnProps {
    mapRenderFunction: (props: InteractionModeMapProps) => ReactNode;
    mapContainerWidth: number;
}

interface DispatchProps {}

interface StateProps {
    mode: InteractionModeType;
}

type InteractionModeContainerProps = StateProps & DispatchProps & OwnProps;

export interface MapInteractions {
    handleMouseMove: (event: MapLayerMouseEvent, mapRef?: RefObject<MapRef>) => void;
    handleMapOnClick: (event: MapLayerMouseEvent) => void;
    onContextMenu: (
        mapRef: React.RefObject<MapRef>,
        event: MapLayerMouseEvent,
    ) => void;
}

export interface InteractionModeContainerState {
    mapProps: InteractionModeMapProps | null;
}

const MODES: { id: InteractionModeType; handler: any }[] = [
    { id: "standard", handler: InteractionModeStandard },
    { id: "measure-distance", handler: InteractionModeMeasureDistance },
    { id: "measure-area", handler: InteractionModeMeasureArea },
];

class InteractionModeContainer extends Component<
    InteractionModeContainerProps,
    InteractionModeContainerState
> {
    state = {
        mapProps: null,
    };

    modeRef: React.RefObject<
        | typeof InteractionModeStandard
        | typeof InteractionModeMeasureDistance
        | typeof InteractionModeMeasureArea
    >;

    constructor(props: InteractionModeContainerProps) {
        super(props);
        this.modeRef = React.createRef();
    }

    updateMapProps = (props: InteractionModeMapProps) => {
        this.setState({
            mapProps: props,
        });
    };

    render() {
        const InteractionMode = MODES.find(
            (mode) => mode.id === this.props.mode,
        )!.handler;
        return (
            <>
                <InteractionMode
                    updateModeProps={this.updateMapProps}
                    mapContainerWidth={this.props.mapContainerWidth}
                />
                {this.state.mapProps &&
                    this.props.mapRenderFunction(this.state.mapProps!)}
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    mode: getStoreAtNamespaceKey(state, "report").interactionMode,
});

export default connect(mapStateToProps)(InteractionModeContainer);
