import React, { FC, MouseEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import cx from "classnames";

import ScrollableText from "../../../_Library/ScrollableText/ScrollableText";

import classes from "./EventDropdown.module.css";
import { DropdownVisibilityContext } from "../NavBar";
import Modal from "components/_Library/Modal/Modal";
import EventsListModal from "components/Partials/NavBar/EventDropdown/EventsListModal/EventsListModal";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { eventTypeToIconPathSwitch } from "components/Pages/Report/DashboardComponents/Summary/Summary";
import { Flex, LoadingOverlay } from "@mantine/core";
import { useApiQuery } from "hooks/useAPI";
import { EventSchema } from "crud/eventsCRUD";
import { startCase } from "lodash";
import { IconX } from "@tabler/icons-react";

const EventDropdown: FC = () => {
    const dropdownVisibilityContext = useContext(DropdownVisibilityContext);
    const { trackUserEvent } = useAnalytics();

    const { data: events, isLoading } = useApiQuery<EventSchema[]>(
        "/events/report",
        ["events_report"],
    );

    const [eventFilter, setEventFilter] = useState<string>("");

    const [eventModalOpen, setEventModalOpen] = useState(false);

    return (
        <div
            className={cx(classes.NavItem, classes.EventNavItem)}
        >
            <div className={classes.EventDropdownSearch}>
                <Icon path={mdiMagnify} size={1} />
                <input
                    placeholder={"Search for an event"}
                    type="text"
                    onChange={(event) =>
                        setEventFilter(event.target.value)
                    }
                    onClick={() => {
                        if (!dropdownVisibilityContext.state.event) {
                            trackUserEvent({ name: "events_navbar_clicked" });
                        }
                        dropdownVisibilityContext.setState({
                            investigations: false,
                            help: false,
                            account: false,
                            event: true,
                        });
                    }}
                    value={eventFilter}
                />

                
                <IconX
                    style={{
                        display:
                            eventFilter === "" ? "none" : "unset",
                    }}
                    size={13}
                    onClick={() => {
                        setEventFilter("");
                    }}
                />
            </div>
            {dropdownVisibilityContext.state.event && (
                <div
                    className={classes.EventDropdown}
                    onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                    }}
                >
                    <LoadingOverlay visible={isLoading} />
                    
                    <div className={classes.EventList}>
                        {events &&
                            events
                                .filter(
                                    (event) =>
                                        event.has_report &&
                                        startCase(event.name.toLowerCase())
                                            .includes(
                                                startCase(eventFilter.toLowerCase()),
                                            ),
                                )
                                .filter((event, index) => index < 5)
                                .map((event) => {
                                    const { path, className } =
                                        eventTypeToIconPathSwitch[event.type];
                                    let classNameProp = {};
                                    if (className) {
                                        classNameProp = {
                                            className: `${className}`,
                                        };
                                    }
                                    const iconProps = {
                                        size: 1.5,
                                        path,
                                        ...classNameProp,
                                    };
                                    return (
                                        <Link className={classes.EventLink} to={`/report/${event.id}`}>
                                            <div
                                                className={
                                                    classes.EventDropdownItem
                                                }
                                                onClick={() => {
                                                    trackUserEvent({
                                                        name: "event_viewed",
                                                        payload: {
                                                            source: "event_dropdown",
                                                            event_id: event.id,
                                                            event_name: event.name,
                                                        },
                                                    });
                                                    dropdownVisibilityContext.setState(
                                                        {
                                                            investigations: false,
                                                            help: false,
                                                            account: false,
                                                            event: false,
                                                        },
                                                    );
                                                }}
                                            >
                                                    <Flex
                                                        wrap="nowrap"
                                                        gap="md"
                                                        align="center"
                                                        className={classes.ItemText}
                                                    >
                                                        <div
                                                            className={
                                                                classes.EventTypeIcon
                                                            }
                                                        >
                                                            <Icon {...iconProps} />
                                                        </div>
                                                        <ScrollableText
                                                            text={event.name}
                                                        />
                                                    </Flex>
                                            </div>
                                        </Link>

                                    );
                                })}
                        <div
                            className={cx(
                                classes.EventDropdownItem,
                                classes.EventPageLink,
                            )}
                            onClick={() => {
                                trackUserEvent({
                                    name: "show_all_events_clicked",
                                });
                                setEventModalOpen(true);
                            }}
                        >
                            <p>Show all events</p>
                        </div>
                    </div>
                </div>
            )}
            {eventModalOpen && (
                <Modal
                    contentClassName={classes.disableScroll}
                    closeModal={() => setEventModalOpen(false)}
                >
                    <EventsListModal
                        initialSearchTerm={eventFilter}
                        onEventSelect={() => {
                            setEventModalOpen(false);
                            dropdownVisibilityContext.setState({
                                investigations: false,
                                help: false,
                                account: false,
                                event: false,
                            });
                        }}
                    />
                </Modal>
            )}
        </div>
    );
};

export default EventDropdown;
