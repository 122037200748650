import {
    MapRef,
    NavigationControl,
    ScaleControl,
    GeoJSONSource,
    MapMouseEvent,
    MapGeoJSONFeature,
    MapLayerMouseEvent,
} from "react-map-gl";
import {
    LayerSelectPopupInfo,
    MapContextMenuInfo,
} from "components/Pages/Report/DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import {
    BasemapType,
    LayerInfo,
    MapActionTypes,
    ConfigSource,
    ReportState,
} from "store/report/reportTypes";
import React, { Component, ReactNode, RefObject } from "react";
import LayerSelectPopup from "components/Pages/Report/DashboardComponents/Map/Popups/LayerSelectPopup/LayerSelectPopup";

import { minimapCenter } from "utils/Coordinates";
import LatLongControl from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/LatLongControl/LatLongControl";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import classes from "components/_Library/Dropdown/ActionDropdown.module.css";
import {
    setActiveTab,
    setBasemap,
    setClickedFeatureProperties,
    setLegendPopup,
    setMousePosition,
    setHighlightedLayer,
} from "store/report/reportActions";
import { connect } from "react-redux";
import { setAlert } from "store/system/systemActions";
import { RootState } from "store/store";
import {
    InteractionModeMapProps,
    MapInteractions,
} from "components/Pages/Report/DashboardComponents/Map/Mapping/MapInteractions/InteractionModeContainer/InteractionModeContainer";
import Legend from "../../../../Legend/Legend";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import {
    clusterLeavesFunc as getClusterLeaves,
    calculateSegmentMagnitudes,
} from "utils/Clusters";
import { InsightsState } from "store/insights/insightsTypes";
import { toggleMarker } from "store/insights/insightsActions";
import LabelControl from "../../../Controls/LabelControl/LabelControl";
import MapContextMenu from "../../../../Popups/MapContextMenu/MapContextMenu";

import Minimap from "../../../Controls/BasemapControl/Minimap/Minimap";
import { Place } from "react-tooltip";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";
import ToolsControl from "../../../Controls/ToolsControl/ToolsControl";
import { EstimatedExposure } from "components/_Library/EstimatedExposure/EstimatedExposure";

export interface OwnProps {
    mapContainerWidth: number;
    updateModeProps: (props: InteractionModeMapProps) => void;
}

interface DispatchProps {
    setMousePosition: typeof setMousePosition;
    setSelectedLayer: typeof setHighlightedLayer; // sets the dataset subset from the selected layer's dataset. for eg, on a country layer with attached dataset, would select the clicked country.
    setAlert: typeof setAlert;
    setClickedFeatureProperties: typeof setClickedFeatureProperties;
    setActiveTab: typeof setActiveTab;
    setBasemap: typeof setBasemap; // for layers which have an api for individual features. This fetches the data.
    setLegendPopup: typeof setLegendPopup;
    toggleMarker: typeof toggleMarker;
}

interface StateProps {
    layersConfig: ReportState["layersConfig"];
    mapboxToken: string;
    basemaps: [BasemapType, BasemapType]; // Basemap styles
    basemapOptions: { [key in BasemapType]: string }; // Basemap styles
    selectedLayer: { layerName: string; sourceName: string } | null;
    legendPopup: { layerName: string; sourceName: string } | null;
    insightsMarker: InsightsState["marker"];
    assessmentType: InsightsState["assessmentType"];
    selectedPeril: InsightsState["selectedPeril"];
    insightsStyles: InsightsState["insightsStyles"];
}

type InteractionModeStandardProps = StateProps &
    DispatchProps &
    OwnProps &
    withAnalyticsProps;

export interface InteractionModeStandardState {
    contextMenuInfo: MapContextMenuInfo;
    popupInfo: LayerSelectPopupInfo;
    basemapControlsExpanded: boolean;
    basemapLeftControlsExpanded: boolean;
}

const FEATURE_INFO_LIMIT = 20; // arbitrary limit of 20

class InteractionModeStandard extends Component<
    InteractionModeStandardProps,
    InteractionModeStandardState
> {
    state: InteractionModeStandardState = {
        contextMenuInfo: {
            latitude: 0,
            longitude: 0,
            display: false,
            mapRef: { current: null },
        },
        popupInfo: {
            latitude: 0,
            longitude: 0,
            layers: Array<LayerInfo>(),
        },
        basemapControlsExpanded: false,
        basemapLeftControlsExpanded: false,
    };

    toggleBasemapControls = () => {
        this.setState({
            ...this.state,
            basemapControlsExpanded: !this.state.basemapControlsExpanded,
        });

        let offClickListener = (e: MouseEvent) => {
            if (
                !(e.target as HTMLElement).closest(`.${classes.BasemapControl}`)
            ) {
                this.setState({
                    basemapControlsExpanded: false,
                });
                document.removeEventListener("click", offClickListener);
            }
        };
        document.addEventListener("click", offClickListener);
        this.props.analytics.trackUserEventWithCurrentEvent({
            name: "basemaps_clicked",
        });
    };

    toggleBasemapLeftControls = () => {
        this.setState({
            ...this.state,
            basemapLeftControlsExpanded:
                !this.state.basemapLeftControlsExpanded,
        });

        let offClickListener = (e: MouseEvent) => {
            if (
                !(e.target as HTMLElement).closest(`.${classes.BasemapControl}`)
            ) {
                this.setState({
                    basemapLeftControlsExpanded: false,
                });
                document.removeEventListener("click", offClickListener);
            }
        };
        document.addEventListener("click", offClickListener);
        this.props.analytics.trackUserEventWithCurrentEvent({
            name: "basemaps_clicked",
        });
    };

    onContextMenu = (mapRef: React.RefObject<MapRef>, event: MapMouseEvent) => {
        event.preventDefault();
        this.setState({
            contextMenuInfo: {
                latitude: event.lngLat.lat,
                longitude: event.lngLat.lng,
                display: true,
                mapRef: mapRef,
            },
        });
    };

    handleMouseMove = async (event: MapLayerMouseEvent, mapRef?: RefObject<MapRef>) => {
        this.props.setMousePosition(event.lngLat);
        let cluster: MapGeoJSONFeature[] | undefined = event.features?.filter(
            (el) => el.properties?.cluster,
        );
        if (mapRef && mapRef.current && cluster?.length && this.props.insightsStyles && this.props.insightsStyles[this.props.assessmentType]?.style?.paint) {
            const clusterId = cluster[0]?.properties?.cluster_id;
            if (this.props.insightsMarker?.clusterId === clusterId) return; // if cluster id has not changed do not compute
            // set clusterID to prevent rerender while large values compute
            this.props.toggleMarker({
                marker: {
                    clusterId,
                },
            });
            const clusterSource = mapRef.current.getSource(
                cluster[0].source,
            ) as GeoJSONSource;

            let clusterLeaves = await getClusterLeaves(
                clusterId,
                cluster[0]?.properties?.point_count,
                clusterSource,
                this.props.assessmentType,
                this.props.selectedPeril!,
            );

            this.props.toggleMarker({
                marker: {
                    lnglat: (cluster[0].geometry as GeoJSON.Point).coordinates,
                    cluster: calculateSegmentMagnitudes(
                        clusterLeaves,
                        this.props.insightsStyles[this.props.assessmentType],
                    ),
                    clusterId,
                },
            });
        } else if (this.props.insightsMarker && !cluster?.length) {
            this.props.toggleMarker({
                marker: null,
            });
        }
    };

    handleMapOnClick = (event: MapLayerMouseEvent) => {
        let popupInfo: LayerSelectPopupInfo = { ...this.state.popupInfo };
        this.props.setSelectedLayer(null);
        if (!event?.features) {
            return;
        }

        // ignore right button click
        if (event.type === "contextmenu") {
            return;
        }

        this.setState((prevState) => ({
            contextMenuInfo: {
                ...prevState.contextMenuInfo,
                display: false,
            },
        }));

        popupInfo.longitude = event.lngLat.lng;
        popupInfo.latitude = event.lngLat.lat;
        const layers: LayerInfo[] = [];
        const layerNames: string[] = [];
        const clickedFeatureProperties: { [key: string]: any[] } = {};
        if (event.features.length > FEATURE_INFO_LIMIT) {
            event.features = event.features.slice(0, FEATURE_INFO_LIMIT);
        }
        const featureIdSet = new Set();

        // grab the appropriate data to create the popup interface.
        event.features.forEach((feature) => {
            if (!feature.properties || !feature.layer) return;
            const featureKeys = Object.keys(feature.properties);
            const keyIndex = featureKeys.findIndex(
                (key) =>
                    key.toLowerCase() === "panorama url" ||
                    key.toLowerCase() === "streetview url",
            ); //This presents a number
            const panoIndex = featureKeys[keyIndex]; //This prints out "Panorama Url" or variant thereof
            if (feature.properties.hasOwnProperty("feature_id")) {
                if (featureIdSet.has(feature.properties["feature_id"])) {
                    return;
                }
                featureIdSet.add(feature.properties["feature_id"]);
            }

            if (!layerNames.includes(feature.layer.id)) {
                layerNames.push(feature.layer.id);
                layers.push({
                    name: feature.layer.id,
                    //@ts-ignore
                    type: feature.layer.type,
                    //@ts-ignore
                    source: feature.layer.source,
                    //@ts-ignore
                    paint: feature.layer.paint,
                    panoramaKey: panoIndex,
                });
                clickedFeatureProperties[feature.layer.id] = [
                    feature.properties,
                ];
            } else {
                clickedFeatureProperties[feature.layer.id] = [
                    ...clickedFeatureProperties[feature.layer.id],
                    feature.properties,
                ];
            }
        });

        this.props.analytics.trackUserEventWithCurrentEvent({
            name: "map_clicked",
        });

        popupInfo.layers = layers;
        this.setState({ popupInfo });
        this.props.setClickedFeatureProperties(clickedFeatureProperties);
    };

    // -- DATA
    handlePopupOnClose = () => {
        this.setState({ popupInfo: { ...this.state.popupInfo, layers: [] } });
    };

    minimapControls = (position: Place) => {
        const isRightMapControl = position === "right";
        const mapIdx = isRightMapControl ? 1 : 0;
        const expanded = isRightMapControl
            ? this.state.basemapControlsExpanded
            : this.state.basemapLeftControlsExpanded;
        const toggleFn = isRightMapControl
            ? this.toggleBasemapControls
            : this.toggleBasemapLeftControls;

        return (
            <div
                className={classes.BasemapControl}
                key={`minimap_${position}`}
                id="tourid_BasemapControl"
            >
                <Minimap
                    border
                    key={"br-basemap-control"}
                    // The active name for this layout should be the first or second index in the basemap props
                    // first index is primary basemap, second is secondary basemap (i.e. for dual, slider)
                    name={
                        this.props.basemapOptions[
                            this.props.basemaps[mapIdx]
                        ] as BasemapType
                    }
                    active={false}
                    mapIndex={1}
                    center={minimapCenter}
                    style={
                        this.props.basemapOptions[this.props.basemaps[mapIdx]]
                    }
                    mapboxToken={this.props.mapboxToken}
                    onClick={toggleFn}
                />
                {expanded && (
                    <div className={classes.BasemapsExpandedContainer}>
                        <div className={classes.BasemapsExpanded}>
                            {Object.keys(this.props.basemapOptions).map(
                                (basemapName: string, idx: number) => {
                                    return (
                                        <div
                                            key={basemapName}
                                            className={
                                                classes.IndividualBasemap
                                            }
                                        >
                                            <Minimap
                                                key={basemapName}
                                                name={
                                                    basemapName as BasemapType
                                                }
                                                active={
                                                    this.props.basemaps[
                                                        mapIdx
                                                    ] === basemapName
                                                }
                                                mapIndex={idx}
                                                center={minimapCenter}
                                                style={
                                                    this.props.basemapOptions[
                                                        basemapName as BasemapType
                                                    ]
                                                }
                                                onClick={() => {
                                                    this.props.setBasemap({
                                                        mapIndex: mapIdx,
                                                        basemap:
                                                            basemapName as BasemapType,
                                                    });
                                                    this.props.analytics.trackUserEventWithCurrentEvent(
                                                        {
                                                            name: "basemap_changed",
                                                            payload: {
                                                                type: basemapName as BasemapType,
                                                            },
                                                        },
                                                    );
                                                }}
                                                mapboxToken={
                                                    this.props.mapboxToken
                                                }
                                                tooltipPlacement={"top"}
                                                border={false}
                                            />
                                            <p className={classes.BasemapName}>
                                                {basemapName}
                                            </p>
                                        </div>
                                    );
                                },
                            )}
                        </div>
                        {isRightMapControl && (
                            <div className={classes.LabelControlContainer}>
                                <LabelControl />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // this weird object returned so map types can chose which controls to display where.
    createControls = (): { [key: string]: ReactNode } => {
        const topLeftControls = [
            <NavigationControl
                showCompass={false}
                key={"tl-navigation-control"}
                position="top-left"
            />,
            <EstimatedExposure/>,
            <ToolsControl key={"tl-tools-control"} />,
        ];
        const bottomLeftControls = [
            this.minimapControls(
                this.props.layersConfig.mapType !== "single" ? "left" : "right",
            ),
            <ScaleControl key={"bl-scale-control"} />,
        ];
        const bottomRightControls = [
            <div key={"br-latlong-control"}>
                <LatLongControl key={"br-latlong-control"} />
            </div>,
        ];

        // For dual / split maps we need controls for the second map.
        if (this.props.layersConfig.mapType !== "single") {
            bottomRightControls.unshift(this.minimapControls("right"));
        }

        bottomLeftControls.unshift();
        const topRightControls: any = [];
        if (this.props.legendPopup !== null) {
            let source: ConfigSource | undefined;
            if (
                this.props.layersConfig.sources[
                    this.props.legendPopup.sourceName
                ]
            ) {
                source =
                    this.props.layersConfig.sources[
                        this.props.legendPopup.sourceName
                    ];
            }
            topRightControls.unshift(
                <div
                    key={"tr-basemap-control"}
                    style={{
                        marginTop: "3rem",
                        marginRight: "3rem",
                    }}
                >
                    <Legend
                        paint={source!.paint}
                        layout={source!.layout}
                        type={source!.layerType}
                        complexPaintProperties={source!.complexPaintProperties}
                        toggleLegendPopup={this.closeLegendPopup}
                        layerName={this.props.legendPopup.layerName}
                        layerId={source?.dataLayerId}
                        legendPopup={this.props.legendPopup}
                        parent="InteractionModeStandard"
                        isFiltered={false}
                        layerFilters={{ identifier: null, values: [] }}
                        source={"layer"}
                    />
                </div>,
            );
        }
        return {
            tl: topLeftControls,
            br: bottomRightControls,
            bl: bottomLeftControls,
            tr: topRightControls,
        };
    };

    closeLegendPopup = () => {
        this.props.setLegendPopup(null);
    };

    createPopup = () => {
        return this.state.popupInfo.layers.length ? (
            <LayerSelectPopup
                popupInfo={this.state.popupInfo}
                setActiveTab={this.props.setActiveTab}
                handlePopupOnClose={this.handlePopupOnClose}
            />
        ) : null;
    };

    createContextMenu = () => {
        return this.state.contextMenuInfo.display ? (
            <MapContextMenu
                latitude={this.state.contextMenuInfo.latitude}
                longitude={this.state.contextMenuInfo.longitude}
                zoom={
                    this.state.contextMenuInfo.mapRef.current
                        ?.getMap()
                        .getZoom()!
                }
                callOnClick={() =>
                    this.setState({
                        contextMenuInfo: {
                            display: false,
                            latitude: 0,
                            longitude: 0,
                            mapRef: this.state.contextMenuInfo.mapRef,
                        },
                    })
                }
                mapRef={this.state.contextMenuInfo.mapRef}
            />
        ) : null;
    };

    getProps(): InteractionModeMapProps {
        const interactions: MapInteractions = {
            handleMapOnClick: this.handleMapOnClick,
            handleMouseMove: this.handleMouseMove,
            onContextMenu: this.onContextMenu,
        };

        return {
            interactions,
            additionalInteractiveLayerIds: [],
            layers: null,
            popup: this.createPopup(),
            contextMenu: this.createContextMenu(),
            controls: this.createControls(),
        };
    }
    componentDidMount(): void {
        this.props.updateModeProps(this.getProps());
    }
    componentDidUpdate(
        prevProps: Readonly<InteractionModeStandardProps>,
        prevState: Readonly<InteractionModeStandardState>,
        snapshot?: any,
    ): void {
        this.props.updateModeProps(this.getProps());
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        layersConfig: getStoreAtNamespaceKey(state, "report").layersConfig,
        mapboxToken: getStoreAtNamespaceKey(state, "report").mapboxToken!,
        basemaps: getStoreAtNamespaceKey(state, "report").basemaps,
        basemapOptions: getStoreAtNamespaceKey(state, "report").basemapOptions,
        highlightedLayer: getStoreAtNamespaceKey(state, "report")
            .highlightedLayer,
        legendPopup: getStoreAtNamespaceKey(state, "report").legendPopup,
        insightsMarker: getStoreAtNamespaceKey(state, "insights").marker,
        assessmentType: getStoreAtNamespaceKey(state, "insights")
            .assessmentType,
        selectedPeril: getStoreAtNamespaceKey(state, "insights").selectedPeril,
        insightsStyles: getStoreAtNamespaceKey(state, "insights")
            .insightsStyles,
    };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => {
    return {
        setMousePosition: bindActionCreators(setMousePosition, dispatch),
        setSelectedLayer: bindActionCreators(setHighlightedLayer, dispatch),
        setLegendPopup: bindActionCreators(setLegendPopup, dispatch),
        setAlert: bindActionCreators(setAlert, dispatch),
        setClickedFeatureProperties: bindActionCreators(
            setClickedFeatureProperties,
            dispatch,
        ),
        setActiveTab: bindActionCreators(setActiveTab, dispatch),
        setBasemap: bindActionCreators(setBasemap, dispatch),
        toggleMarker: bindActionCreators(toggleMarker, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withAnalytics(InteractionModeStandard));
