import React from "react";
import { AssessmentType, PerilType } from "store/system/systemTypes";
import { CirclePaint, Expression, GeoJSONSource } from "mapbox-gl";
import _ from "lodash";
import { StyleConfig_Style } from "crud/layerStylesCRUD";
import { extractMatchedProperty, extractPaintCategories } from "./PaintHelpers";

interface DonutSegmentProps {
    start: number;
    end: number;
    r: number;
    r0: number;
    color: string;
    i: number;
    categories: string[];
    insightsStyle: StyleConfig_Style;
}

interface CreateDonutChartProps {
    cluster: { [key: string]: number };
    insightsStyle: StyleConfig_Style;
}

export default function CreateDonutChart({
    cluster,
    insightsStyle,
}: CreateDonutChartProps) {
    const offsets: number[] = [];
    let counts: (number | undefined)[] = [];
    let categories: string[] = [];

    for (let [category, count] of Object.entries(cluster)) {
        counts.push(count);
        categories.push(category);
    }
    let total = 0;
    for (const count of counts) {
        offsets.push(total);
        total += count ?? 0;
    }

    let fontSize: number;
    let radius: number;
    if (total < 10) {
        fontSize = 14;
        radius = 24;
    } else if (total < 100) {
        fontSize = 16;
        radius = 24;
    } else if (total < 750) {
        fontSize = 18;
        radius = 34;
    } else if (total < 10000) {
        fontSize = 20;
        radius = 44;
    } else {
        fontSize = 20;
        radius = 51;
    }
    const r0 = Math.round(radius * 0.6);
    const w = radius * 2;

    return (
        <div style={{ transform: "translate(-50%, -50%)" }}>
            <svg
                width={w}
                height={w}
                viewBox={`-2 -2 ${w + 4} ${w + 4}`}
                textAnchor="middle"
                style={{ font: `${fontSize}px sans-serif`, display: `block` }}
            >
                <circle cx={radius} cy={radius} r={r0} fill="white" />
                {counts.map((count: number | undefined, i: number) => {
                    if (count) {
                        let color: string;

                        if (categories[i] === "Other") {
                            color = (
                                (insightsStyle.style.paint as CirclePaint)[
                                    "circle-color"
                                ] as Expression
                            ).slice(-1)[0];
                        } else {
                            const colorIndex =
                                (
                                    (insightsStyle.style.paint as CirclePaint)[
                                        "circle-color"
                                    ] as Expression
                                ).findIndex(
                                    (expressionElem) =>
                                        expressionElem === categories[i],
                                ) + 1;

                            color = (
                                (insightsStyle.style.paint as CirclePaint)[
                                    "circle-color"
                                ] as Expression
                            )[colorIndex];
                        }

                        return DonutSegment({
                            start: offsets[i] / total,
                            end: (offsets[i] + (count ?? 0)) / total,
                            r: radius,
                            r0: r0,
                            color: color,
                            i,
                            categories,
                            insightsStyle,
                        });
                    } else {
                        return <></>;
                    }
                })}
                <text
                    dominantBaseline="central"
                    transform={`translate(${radius}, ${radius})`}
                >
                    {total.toLocaleString()}
                </text>
            </svg>
        </div>
    );
}

function DonutSegment({
    start,
    end,
    r,
    r0,
    color,
    i,
    categories,
    insightsStyle,
}: DonutSegmentProps) {
    if (end - start === 1) end -= 0.00001;

    let hasBorder = false;
    let borderColor = color;

    if (
        insightsStyle?.style?.paint &&
        insightsStyle?.style?.paint.hasOwnProperty("circle-stroke-color")
    ) {
        if (
            Array.isArray(
                (insightsStyle.style.paint as CirclePaint)[
                    "circle-stroke-color"
                ],
            )
        ) {
            const borderIndex = (
                (insightsStyle.style.paint as CirclePaint)[
                    "circle-stroke-color"
                ] as Expression
            ).findIndex((expressionElem) => expressionElem === categories[i]);

            if (borderIndex !== -1) {
                hasBorder = borderIndex !== -1;

                borderColor = (
                    (insightsStyle.style.paint as CirclePaint)[
                        "circle-stroke-color"
                    ] as Expression
                )[borderIndex + 1];
            }
        } else {
            borderColor = (insightsStyle.style.paint as CirclePaint)[
                "circle-stroke-color"
            ] as string;
        }
    }

    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;
    const pathData = [
        "M",
        r + r0 * x0,
        r + r0 * y0,
        "L",
        r + r * x0,
        r + r * y0,
        "A",
        r,
        r,
        0,
        largeArc,
        1,
        r + r * x1,
        r + r * y1,
        "L",
        r + r0 * x1,
        r + r0 * y1,
        "A",
        r0,
        r0,
        0,
        largeArc,
        0,
        r + r0 * x0,
        r + r0 * y0,
    ].join(" ");

    const styles = {
        strokeOpacity: hasBorder ? 1 : 0,
        fill: color,
        strokeWidth: 1.5,
        stroke: borderColor,
    };

    // draw an SVG path
    return <path key={i} d={pathData} style={styles} />;
}

export const createCluster = (
    features: GeoJSON.Feature[],
    insightsStyle: StyleConfig_Style,
): { [assessmentCategory: string]: number } => {
    const paintCategories = extractPaintCategories(insightsStyle);
    const matchedProperty = extractMatchedProperty(insightsStyle);

    paintCategories.push("Other");

    const categoryCounts = new Map<string, number>();

    for (const paintCategory of paintCategories) {
        categoryCounts.set(paintCategory, 0);
    }

    for (const feature of features) {
        const assessment = feature.properties![matchedProperty] || "Other";
        if (assessment) {
            categoryCounts.set(assessment, categoryCounts.get(assessment)! + 1);
        }
    }

    return Object.fromEntries(categoryCounts);
};

export const calculateSegmentMagnitudes = _.memoize(
    (features, insightsStyle: StyleConfig_Style) =>
        createCluster(features, insightsStyle),
    (features, insightsStyle) => {
        return features.length + insightsStyle.name;
    },
);

const getClusterLeaves = (
    id: number,
    count: number,
    source: GeoJSONSource,
): Promise<GeoJSON.Feature[]> => {
    return new Promise((res, rej) => {
        source.getClusterLeaves(
            id,
            count,
            0,
            (error: any, features: GeoJSON.Feature[]) => {
                if (!error) {
                    res(features);
                }
            },
        );
    });
};

export const clusterLeavesFunc = _.memoize(
    (
        clusterId: number,
        pointCount: number,
        clusterSource: GeoJSONSource,
        assessmentType: AssessmentType,
        selectedPeril: PerilType,
    ) => getClusterLeaves(clusterId, pointCount, clusterSource),
    (clusterId, pointCount, clusterSource, assessmentType, selectedPeril) => {
        return `${clusterId}-${assessmentType}-${selectedPeril}`;
    },
);
