import { MapActionTypes } from "store/report/reportTypes";
import React, { Component, ReactNode } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
    featureCollection,
    multiLineString,
    Position,
    point,
} from "@turf/helpers";
import { Layer, LayerProps, MapLayerMouseEvent, Source } from "react-map-gl";
import {
    InteractionModeMapProps,
    MapInteractions,
} from "../../InteractionModeContainer/InteractionModeContainer";
import length from "@turf/length";
import classes from "../InteractionModes.module.css";
import { MeasurePopupInfo } from "components/Pages/Report/DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import { getCssVar } from "utils/CSSHelpers";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import cx from "classnames";
import { bindActionCreators } from "redux";
import { setInteractionMode } from "store/report/reportActions";
import { setCookie, getCookie } from "utils/Cookies";
import Button from "components/_Library/Button/Button";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";

export interface OwnProps {
    mapContainerWidth: number;
    updateModeProps: (props: InteractionModeMapProps) => void;
}
interface DispatchProps {
    setInteractionMode: typeof setInteractionMode;
}
interface StateProps {}
type InteractionModeMeasureDistanceProps = StateProps &
    DispatchProps &
    OwnProps &
    withAnalyticsProps;

enum SystemOfMeasurement {
    metric = "metric",
    imperial = "imperial",
}

export interface InteractionModeMeasureDistanceState {
    clickedLngLats: Position[];
    popupInfo: MeasurePopupInfo;
    drawState: DrawState;
    segDist: number | null;
    hoverLngLat: Position | null;
    systemOfMeasurement: SystemOfMeasurement;
}
type DrawState = "IDLE" | "MEASURING" | "DISPLAY" | "INACTIVE";

class InteractionModeMeasureDistance extends Component<
    InteractionModeMeasureDistanceProps,
    InteractionModeMeasureDistanceState
> {
    distanceUnitCookie = getCookie("distance-unit");
    state: InteractionModeMeasureDistanceState = {
        drawState: "IDLE",
        clickedLngLats: [],
        popupInfo: {
            latitude: 0,
            longitude: 0,
            measurement: null,
        },
        segDist: null,
        hoverLngLat: null,
        systemOfMeasurement: this.distanceUnitCookie
            ? (this.distanceUnitCookie as SystemOfMeasurement)
            : SystemOfMeasurement.metric,
    };

    measurementSystems = {
        metric: {
            unitsShort: "km",
            subUnitsShort: "m",
            divisor: 1000,
        },
        imperial: {
            unitsShort: "mi",
            subUnitsShort: "ft",
            divisor: 5280,
        },
    };

    handleGetCursor = (state: {
        isLoaded: boolean;
        isDragging: boolean;
        isHovering: boolean;
    }) => {
        // the cursor returned from this function take precedence over marker cursor.
        return "crosshair";
    };

    handleMouseMove = (event: MapLayerMouseEvent) => {
        if (this.state.drawState === "MEASURING") {
            this.setState({
                hoverLngLat: [event.lngLat.lng, event.lngLat.lat],
            });
        }
    };

    onContextMenu = () => {
        return null;
    };

    handleMapOnClick = (event: MapLayerMouseEvent) => {
        let { clickedLngLats, drawState } = this.state;
        let dist: number | null = null;

        if (this.state.drawState === "IDLE") {
            clickedLngLats.push([event.lngLat.lng, event.lngLat.lat]);
            drawState = "MEASURING";
        } else if (this.state.drawState === "MEASURING") {
            clickedLngLats.push([event.lngLat.lng, event.lngLat.lat]);
        } else if (this.state.drawState === "INACTIVE") {
            this.setState({
                popupInfo: {
                    latitude: 0,
                    longitude: 0,
                    measurement: null,
                },
                segDist: null,
                drawState: "IDLE",
            });
            return;
        }

        const lineData = multiLineString([[...clickedLngLats]]);
        let segDist: number | null = null;
        dist = length(lineData, {
            units:
                this.state.systemOfMeasurement === SystemOfMeasurement.metric
                    ? "kilometers"
                    : "miles",
        });

        if (clickedLngLats.length > 1) {
            const segLngLats = [
                clickedLngLats[clickedLngLats.length - 2],
                clickedLngLats[clickedLngLats.length - 1],
            ];
            const segLineData = multiLineString([segLngLats]);
            segDist = length(segLineData, {
                units:
                    this.state.systemOfMeasurement ===
                    SystemOfMeasurement.metric
                        ? "kilometers"
                        : "miles",
            });
        }

        this.setState({
            popupInfo: {
                latitude: event.lngLat.lat,
                longitude: event.lngLat.lng,
                measurement: dist,
            },
            clickedLngLats: [...clickedLngLats],
            drawState,
            segDist,
        });

        this.props.analytics.trackUserEventWithCurrentEvent({
            name: "measure_tool_point_clicked",
        });
    };

    handleMeasureReset = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        this.setState({
            drawState: "INACTIVE",
            clickedLngLats: [],
            popupInfo: {
                latitude: 0,
                longitude: 0,
                measurement: null,
            },
            segDist: null,
        });
    };

    /**
     * Converts miles to kilometers
     * @param miles
     * @returns kilometers
     */
    milesToKilometers = (miles: number) => {
        return miles * 1.60934;
    };

    /**
     * Converts kilometers to miles
     * @param kilometers
     * @returns miles
     */
    kilometersToMiles = (kilometers: number) => {
        return kilometers * 0.621371;
    };

    /**
     * Recalculates the segment and total distance; sets user cookie for pref
     * @param e click event
     * @param sysOfMeasure the selected system of measurement
     */
    handleSystemUnitChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        sysOfMeasure: SystemOfMeasurement,
    ) => {
        e.stopPropagation();
        if (this.state.systemOfMeasurement !== sysOfMeasure) {
            const conversionFn =
                sysOfMeasure === SystemOfMeasurement.imperial
                    ? this.kilometersToMiles
                    : this.milesToKilometers;
            const measurement = conversionFn(
                this.state.popupInfo.measurement ?? 0,
            );
            const segDist = conversionFn(this.state.segDist ?? 0);
            this.setState({
                systemOfMeasurement: sysOfMeasure,
                popupInfo: {
                    latitude: 0,
                    longitude: 0,
                    measurement,
                },
                segDist,
            });
            setCookie("distance-unit", sysOfMeasure, 30);
        }
    };

    // this weird object returned so map types can chose which controls to display where.
    createControls = (): { [key: string]: ReactNode } => {
        return {
            tl: [
                <div className={classes.MeasurePopup}>
                    {this.state.segDist != null && this.state.segDist >= 1 ? (
                        <div className={classes.MeasureItem}>
                            {`Last Segment:
                                ${this.state.segDist?.toFixed(2)} 
                                ${
                                    this.measurementSystems[
                                        this.state.systemOfMeasurement
                                    ].unitsShort
                                }
                            `}
                        </div>
                    ) : (
                        <div className={classes.MeasureItem}>
                            {`Last Segment:
                                ${(this.state.segDist
                                    ? this.state.segDist *
                                      this.measurementSystems[
                                          this.state.systemOfMeasurement
                                      ].divisor
                                    : 0
                                ).toFixed(2)} 
                                ${
                                    this.measurementSystems[
                                        this.state.systemOfMeasurement
                                    ].subUnitsShort
                                }
                            `}
                        </div>
                    )}
                    {this.state.popupInfo.measurement != null &&
                    this.state.popupInfo.measurement >= 1 ? (
                        <div className={classes.MeasureItem}>
                            <p>
                                {`Total Distance:
                                    ${this.state.popupInfo.measurement?.toFixed(
                                        3,
                                    )} 
                                    ${
                                        this.measurementSystems[
                                            this.state.systemOfMeasurement
                                        ].unitsShort
                                    }
                                `}
                            </p>
                        </div>
                    ) : (
                        <div className={classes.MeasureItem}>
                            <p>
                                {`Total Distance:
                                    ${(this.state.popupInfo.measurement
                                        ? this.state.popupInfo.measurement *
                                          this.measurementSystems[
                                              this.state.systemOfMeasurement
                                          ].divisor
                                        : 0
                                    ).toFixed(2)} 
                                    ${
                                        this.measurementSystems[
                                            this.state.systemOfMeasurement
                                        ].subUnitsShort
                                    }
                                `}
                            </p>
                        </div>
                    )}
                    <div>
                        <div
                            className={cx(classes.SegmentButton, {
                                [classes.active]:
                                    this.state.systemOfMeasurement ===
                                    SystemOfMeasurement.metric,
                            })}
                            onClick={(event) => {
                                this.handleSystemUnitChange(
                                    event,
                                    SystemOfMeasurement.metric,
                                );
                            }}
                        >
                            Metric
                        </div>
                        <div
                            className={cx(classes.SegmentButton, {
                                [classes.active]:
                                    this.state.systemOfMeasurement ===
                                    SystemOfMeasurement.imperial,
                            })}
                            onClick={(event) => {
                                this.handleSystemUnitChange(
                                    event,
                                    SystemOfMeasurement.imperial,
                                );
                            }}
                        >
                            Imperial
                        </div>
                    </div>
                    <Button
                        onClick={this.handleMeasureReset}
                        size={{ width: "8rem", height: "2.2rem" }}
                        type="neutral"
                    >
                        Reset
                    </Button>
                </div>,
                <div
                    className={classes.QuitPopup}
                    onClick={() => {
                        this.props.setInteractionMode("standard");
                        this.props.analytics.trackUserEventWithCurrentEvent({
                            name: "measure_tool_clicked",
                            payload: {
                                type: "measure_distance",
                                status: "end",
                            },
                        });
                    }}
                    id="tourid_ExitMeasureDistance"
                >
                    Click here to exit Measure Distance mode{" "}
                    <Icon path={mdiClose} />
                </div>,
            ],
            br: [],
            bl: [],
            tr: [],
        };
    };

    createPopup = () => {
        return null;
    };

    createContextMenu = () => {
        return null;
    };

    createLayers = () => {
        const lngLats = this.state.hoverLngLat
            ? [...this.state.clickedLngLats, this.state.hoverLngLat]
            : [...this.state.clickedLngLats];

        const lineData = multiLineString([lngLats]);
        const pointData = lngLats.map((elem, index) => {
            return point(elem, { id: index });
        });

        const pointLayerStyle: LayerProps = {
            id: "measure-point",
            type: "circle",
            paint: {
                "circle-radius": 5,
                "circle-color": getCssVar("--highlight-color"),
            },
        };

        const lineLayerStyle: LayerProps = {
            id: "measure-line",
            type: "line",
            paint: { "line-color": getCssVar("--highlight-color") },
        };

        return [
            <Source
                key={"additional-1"}
                id="additional-1"
                type="geojson"
                data={featureCollection([lineData])}
            >
                <Layer {...lineLayerStyle} />
            </Source>,
            <Source
                key={"additional-2"}
                id="additional-2"
                type="geojson"
                data={featureCollection(pointData)}
            >
                <Layer {...pointLayerStyle} />
            </Source>,
        ];
    };

    componentDidMount(): void {
        this.props.updateModeProps(this.getProps());
    }

    componentDidUpdate(
        prevProps: Readonly<InteractionModeMeasureDistanceProps>,
        prevState: Readonly<InteractionModeMeasureDistanceState>,
        snapshot?: any,
    ): void {
        this.props.updateModeProps(this.getProps());
    }

    getProps(): InteractionModeMapProps {
        const interactions: MapInteractions = {
            handleMapOnClick: this.handleMapOnClick,
            handleMouseMove: this.handleMouseMove,
            onContextMenu: this.onContextMenu,
        };

        return {
            interactions,
            additionalInteractiveLayerIds: [],
            layers: this.createLayers(),
            popup: this.createPopup(),
            controls: this.createControls(),
            contextMenu: this.createContextMenu(),
        };
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => {
    return {
        setInteractionMode: bindActionCreators(setInteractionMode, dispatch),
    };
};

export default connect(
    null,
    mapDispatchToProps,
)(withAnalytics(InteractionModeMeasureDistance));
