import MapboxGeocoder, { Result } from "@mapbox/mapbox-gl-geocoder";
import React, { FC, useCallback, useEffect } from "react";
import { MapRef } from "react-map-gl";
import { useDispatch } from "react-redux";
import { setMapMarkerLocation } from "store/report/reportActions";
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
import { SearchesType, SearchType } from "../GeocoderTab";

interface GeocoderProps {
    containerRef: React.RefObject<HTMLDivElement>;
    mapRef: React.RefObject<MapRef>;
    pastSearches: SearchesType;
    setPastSearches: React.Dispatch<React.SetStateAction<SearchesType>>;
}

const Geocoder: FC<GeocoderProps> = ({
    containerRef,
    mapRef,
    pastSearches,
    setPastSearches,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const mapboxGeocoder = new MapboxGeocoder({
            accessToken: import.meta.env.VITE_MAPBOX_TOKEN!,
            localGeocoder: (query: string) => {
                let matches = query.match(
                    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
                );
                if (!matches) {
                    return [];
                }

                function coordinateFeature(
                    lng: number,
                    lat: number,
                ): Omit<Result, "bbox"> {
                    return {
                        center: [lng, lat],
                        geometry: {
                            type: "Point",
                            coordinates: [lng, lat],
                        },
                        place_name: "Lat: " + lat + " Lng: " + lng,
                        place_type: ["coordinate"],
                        properties: {},
                        type: "Feature",
                        relevance: 1,
                        text: "Lat: " + lat + " Lng: " + lng,
                        context: [],
                        address: "",
                    };
                }

                function latitudeError(
                    lng: number,
                    lat: number,
                ): Omit<Result, "bbox"> {
                    lat = 90;
                    return {
                        center: [lng, lat],
                        geometry: {
                            type: "Point",
                            coordinates: [lng, lat],
                        },
                        place_name:
                            "Lat should be less than 90, Lat: " +
                            lat +
                            " Lng: " +
                            lng,
                        place_type: ["coordinate"],
                        relevance: 1,
                        text: "Lat: " + lat + " Lng: " + lng,
                        context: [],
                        type: "Feature",
                        address: "",
                        properties: {},
                    };
                }

                let coord1 = Number(matches[1]);
                let coord2 = Number(matches[2]);
                let geocodes: Result[] = [];

                if (coord1 > 90 && coord2 > 90) {
                    geocodes.push(latitudeError(coord1, coord2) as Result);
                    geocodes.push(latitudeError(coord2, coord1) as Result);
                } else {
                    if (coord1 < -90 || coord1 > 90) {
                        geocodes.push(
                            coordinateFeature(coord1, coord2) as Result,
                        );
                    }

                    if (coord2 < -90 || coord2 > 90) {
                        geocodes.push(
                            coordinateFeature(coord2, coord1) as Result,
                        );
                    }
                }

                if (geocodes.length === 0) {
                    geocodes.push(coordinateFeature(coord1, coord2) as Result);
                    geocodes.push(coordinateFeature(coord2, coord1) as Result);
                }

                return geocodes;
            },
        });

        mapboxGeocoder.on("result", (event) => {
            storeSearches(event);

            dispatch(
                setMapMarkerLocation([
                    event.result.center[0],
                    event.result.center[1],
                ]),
            );

            if (event.result.bbox) {
                mapRef.current?.fitBounds(event.result.bbox);
            } else {
                mapRef.current?.flyTo({
                    center: event.result.center,
                    zoom: 12,
                });
            }
        });

        if (
            containerRef.current !== null &&
            containerRef.current.children.length === 0
        ) {
            mapboxGeocoder.addTo(containerRef.current!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const storeSearches = useCallback(
        (search: SearchType) => {
            const duplicateSearch = pastSearches.searches.find((pastSearch) => {
                //Check if search already exists
                return pastSearch.place_name === search.result.place_name;
            });

            if (!duplicateSearch) {
                if (search.result.bbox) {
                    pastSearches.searches.push({
                        ...search.result,
                    });
                }
            }

            localStorage.setItem("pastSearches", JSON.stringify(pastSearches));

            setPastSearches(pastSearches);

            let searchBox = document.getElementsByClassName(
                "mapboxgl-ctrl-geocoder--input",
            )[0] as HTMLInputElement;
            searchBox.value = "";
        },
        [pastSearches, setPastSearches],
    );

    return null;
};

export default Geocoder;
