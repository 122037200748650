import Button from "components/_Library/Button/Button";
import React, { useState } from "react";
import classes from "./Insights.module.css";
import cx from "classnames";
import Spinner from "components/_Library/Spinner/Spinner";
import Icon from "@mdi/react";
import { mdiPublish, mdiUndo } from "@mdi/js";
import ConfirmModal from "components/_Library/ConfirmModal/ConfirmModal";
import { useOktaAuth } from "@okta/okta-react";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { setAlert } from "store/system/systemActions";
import { useDispatch, useSelector } from "react-redux";
import LocationInsights from "./LocationInsights";
import { PolicyInsights } from "./PolicyInsights";
import { RootState } from "store/store";
import usePortfolioRevisions from "./usePortfolioRevisions";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { Badge, SegmentedControl } from "@mantine/core";
import buttonClasses from "../../../../_Library/Button/Button.module.css";
import { useHistory } from "react-router-dom";
import PrettySelect from "../../../../_Library/Select/PrettySelect";
import { formatExposureClaims } from "./utils";
import {
    SetAssessmentFilters,
    setSelectedPeril,
} from "store/insights/insightsActions";
import { PerilType } from "store/system/systemTypes";
import { CapitaliseWord } from "utils/String";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { setDashboardView } from "store/report/reportActions";
import { useUserMeAccessQuery } from "crud/me_access";

type Props = {
    eventId: string;
};

export const InsightsHeader = ({ eventId }: Props) => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const [loadingPublish, setLoadingPublish] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const dashboardView = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").dashboardView,
    );
    const history = useHistory();
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );

    const selectedPeril = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedPeril,
    );
    const selectedRevisionId = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedRevisionId,
    );
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const {
        handlePortfolioChange,
        handleRevisionChange,
        selectedPortfolio,
        selectedRevisionPublished,
        setSelectedRevisionPublished,
        revisions,
        revisionsBySelectedPeril,
        refreshInsights,
    } = usePortfolioRevisions({ user, eventId: eventId! });

    // Ensure BarChart filters do not persist through different events / portfolios
    dispatch(
        SetAssessmentFilters({
            assessmentType: "claims",
            assessmentFilter: null,
        }),
    );
    dispatch(
        SetAssessmentFilters({
            assessmentType: "exposure",
            assessmentFilter: null,
        }),
    );

    const togglePublish = async () => {
        try {
            let token = oktaAuth.getAccessToken();
            // if the revision is already published, unpublish it
            const publisher = selectedRevisionPublished ? null : user?.email;

            setLoadingPublish(true);
            setConfirmModal(false);

            const res = await fetch(
                `${
                    import.meta.env.VITE_API_ROOT
                }/events/${eventId}/insights/${selectedRevisionId}/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    method: "PATCH",
                    body: JSON.stringify({
                        publisher: publisher,
                    }),
                },
            );
            if (!isUnsuccessfulAPIResponse(res)) {
                await refreshInsights();
                setSelectedRevisionPublished(Boolean(publisher));
            }
        } catch (error) {
            dispatch(
                setAlert({
                    message: "Failed",
                    type: "Error",
                }),
            );
        } finally {
            setLoadingPublish(false);
        }
    };

    const generateIterationDropdown = () => {
        if (
            !(
                user?.is_admin &&
                user?.portfolios &&
                user?.portfolios.length > 0 &&
                selectedPeril
            )
        ) {
            return null;
        }
        return (
            <div className={classes.RevisionContainer}>
                <div className={classes.PortfolioDropdown}>
                    <span className={classes.Label}>Revision:</span>
                    <PrettySelect
                        data={revisionsBySelectedPeril.map((revision) => revision.label)}
                        defaultValue={
                            revisionsBySelectedPeril.find(
                                (revision) =>
                                    revision.id === selectedRevisionId,
                            )?.label!
                        }
                        onChange={(value: string) => {
                            handleRevisionChange(value, selectedPeril);
                        }}
                        placeholder={"Select a Revision"}
                    />
                </div>
                <span
                    style={{
                        marginLeft: "1rem",
                        alignSelf: "flex-start",
                    }}
                >
                    <Button
                        disabled={!selectedRevisionId || loadingPublish}
                        size={{
                            height: "2.5rem",
                            width: "15rem",
                        }}
                        onClick={() => setConfirmModal(true)}
                    >
                        {selectedRevisionPublished ? (
                            <>
                                Unpublish
                                <Icon path={mdiUndo} size={1} />
                            </>
                        ) : (
                            <>
                                Publish
                                <Icon path={mdiPublish} size={1} />
                            </>
                        )}
                    </Button>
                </span>
            </div>
        );
    };

    const generatePortfolioDropdown = () => {
        return (
            <div className={classes.PortfolioDropdown}>
                <span className={classes.Label}>Portfolio:</span>
                <PrettySelect
                    defaultValue={
                        selectedPortfolio?.name ||
                        user?.portfolios[0]?.name ||
                        ""
                    }
                    onChange={handlePortfolioChange}
                    onClick={() => {
                        trackUserEventWithCurrentEvent({
                            name: "portfolio_dropdown_clicked",
                            payload: {
                                source: "insights",
                            },
                        });
                    }}
                    data={
                        user?.portfolios.map((portfolio) => portfolio.name) ??
                        []
                    }
                    placeholder={"Select a Portfolio"}
                    searchable
                />
            </div>
        );
    };

    const generatePerilDropdown = () => {
        const availablePerils = revisions.map((revision) => revision.peril);

        if (availablePerils.length !== 0) {
            return (
                <div className={classes.PortfolioDropdown}>
                    <span className={classes.Label}>Peril</span>
                    <PrettySelect
                        data={availablePerils.map((peril) =>
                            CapitaliseWord(peril),
                        )}
                        defaultValue={
                            selectedPeril
                                ? CapitaliseWord(selectedPeril)
                                : availablePerils[0]
                        }
                        onClick={() => {
                            trackUserEventWithCurrentEvent({
                                name: "peril_dropdown_clicked",
                                payload: {
                                    source: "insights",
                                },
                            });
                        }}
                        onChange={(value: string) => {
                            trackUserEventWithCurrentEvent({
                                name: "peril_selected",
                                payload: {
                                    from: selectedPeril as PerilType,
                                    to: value.toLowerCase() as PerilType,
                                },
                            });
                            dispatch(
                                setSelectedPeril({
                                    selectedPeril:
                                        value.toLowerCase() as PerilType,
                                }),
                            );
                            const filteredRevisions = revisions.filter(
                                (revision) =>
                                    revision.peril === value.toLowerCase(),
                            );
                            const sortedRevisions =
                                formatExposureClaims(filteredRevisions);

                            handleRevisionChange(
                                sortedRevisions[0].label,
                                value as PerilType,
                            );
                        }}
                        placeholder={"Select a peril"}
                    />
                </div>
            );
        }
    };

    const { data } = useUserMeAccessQuery();

    const insightsTabs = () => {
        if (data?.has_policy_access) {
            return (
                <div className={classes.InsightsTypeToggle}>
                    <SegmentedControl
                        value={dashboardView}
                        onChange={(value) => {
                            trackUserEventWithCurrentEvent({
                                name: "insights_type_selected",
                                payload: {
                                    type:
                                        value === "policy_insights"
                                            ? "policy"
                                            : "location",
                                },
                            });
                            dispatch(
                                setDashboardView({
                                    view: value as
                                        | "insights"
                                        | "policy_insights",
                                    history: history,
                                }),
                            );
                        }}
                        classNames={{
                            label: classes.InsightsOrPolicyControlLabel,
                            root: classes.InsightsOrPolicyControlRoot,
                        }}
                        size={"md"}
                        radius={"lg"}
                        defaultValue={"insights"}
                        data={[
                            { value: "insights", label: "Location" },
                            {
                                value: "policy_insights",
                                label: (
                                    <>
                                        Policy
                                        <Badge
                                            variant="gradient"
                                            classNames={{
                                                root: cx(
                                                    buttonClasses.ButtonBadgeRoot,
                                                    classes.NoClick,
                                                ),
                                                inner: buttonClasses.ButtonBadgeInner,
                                            }}
                                        >
                                            Beta
                                        </Badge>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <div
                className={cx({
                    [classes.MenuBarNonAdmin]:
                        !user?.is_admin && !data?.has_policy_access,
                    [classes.MenuBarHasPolicy]:
                        user?.is_admin || data?.has_policy_access,
                })}
            >
                {insightsTabs()}
                <div className={classes.DropdownContainer}>
                    {generatePortfolioDropdown()}
                    {generatePerilDropdown()}
                </div>
                {user?.is_admin &&
                    user?.portfolios &&
                    user?.portfolios.length > 0 && (
                        <>{generateIterationDropdown()}</>
                    )}
            </div>
            {dashboardView === "insights" && (
                <LocationInsights
                    eventId={eventId}
                    selectedPortfolio={selectedPortfolio!}
                />
            )}
            {dashboardView === "policy_insights" && user && (
                <PolicyInsights
                    eventId={eventId}
                    selectedPortfolio={selectedPortfolio!}
                />
            )}
            {loadingPublish && (
                <div style={{ zIndex: 1000 }}>
                    <Spinner removeSpinner={() => {}} />
                </div>
            )}
            {confirmModal && (
                <ConfirmModal
                    onCancel={() => setConfirmModal(false)}
                    title="Are you sure?"
                    onConfirm={togglePublish}
                />
            )}
        </>
    );
};
