import { Visibility } from "../report/reportTypes";
import { AssessmentType, EventType, PerilType } from "../system/systemTypes";
import { AnyAction } from "redux";
import { InsightsSchema, RevisionSchema } from "../../crud/insightsCRUD";
import { PortfolioItem } from "store/user/userTypes";
import { DamageAssessment } from "utils/Colors";
import { StyleConfig_Style } from "crud/layerStylesCRUD";

export const ADD_INSIGHTS_LAYER = "ADD_INSIGHTS_LAYER";
export const REMOVE_INSIGHTS_DATA = "REMOVE_INSIGHTS_DATA";
export const SET_INSIGHTS_TYPE = "SET_INSIGHTS_TYPE";
export const SET_INSIGHTS_LOADED = "SET_INSIGHTS_LOADED";
export const SET_INSIGHTS_LAYER_VISIBILITY = "SET_INSIGHTS_LAYER_VISIBILITY";
export const SET_INSIGHTS_LAYER_VIEW = "SET_INSIGHTS_LAYER_VIEW";
export const TOGGLE_DAMAGE_LABEL_FILTER = "TOGGLE_DAMAGE_LABEL_FILTER";
export const SET_CONTRACT_ID_FILTER = "SET_CONTRACT_ID_FILTER";
export const CLEAR_INSIGHTS_FILTERS = "CLEAR_INSIGHTS_FILTERS";
export const TOGGLE_INSIGHTS_VISIBILITY = "TOGGLE_INSIGHTS_VISIBILITY";
export const SET_SELECTED_PORTFOLIO = "SET_SELECTED_PORTFOLIO";
export const SET_EVENT_ID = "SET_EVENT_ID";
export const SET_FETCHING_STATUS = "SET_FETCHING_STATUS";
export const TOGGLE_MARKER = "TOGGLE_MARKER";
export const TOGGLE_CLUSTER = "TOGGLE_CLUSTER";
export const ADD_POLICY_INSIGHTS = "ADD_POLICY_INSIGHTS";
export const LOAD_INSIGHTS = "LOAD_INSIGHTS";
export const SET_INSIGHTS_GEOJSON = "SET_INSIGHTS_GEOJSON";
export const SET_ASSESSMENT_TYPE = "SET_ASSESSMENT_TYPE";
export const SET_FILTERED_INDICES = "SET_FILTERED_INDICES";
export const SET_FILTERED_PROPERTIES = "SET_FILTERED_PROPERTIES";
export const CLEAR_FILTERED_INDICES = "CLEAR_FILTERED_INDICES";
export const SET_SELECTED_PERIL = "SET_SELECTED_PERIL";
export const SET_REVISIONS = "SET_REVISIONS";
export const SET_SELECTED_REVISION_ID = "SET_SELECTED_REVISION_ID";
export const SET_PERIL_TYPE = "SET_PERIL_TYPE";
export const SET_ASSESSMENT_FILTERS = "SET_ASSESSMENT_FILTERS";
export const SET_ALL_INSIGHTS_STYLES = "SET_ALL_INSIGHTS_STYLES";
export const SET_INSIGHTS_STYLES = "SET_INSIGHTS_STYLE";
export const UPDATE_ASSESSMENT_STYLES = "UPDATE_ASSESSMENT_STYLES";

export type SetInsightsLoadedPayload = boolean;
export type SetInsightsTypePayload = InsightsType;
export type SetExposureAssessmentFilterPayload = DamageAssessment | null;
export type SetClaimsAssessmentFilterPayload = DamageAssessment | null;
export type SetAssessmentFiltersPayload = {
    assessmentType: "claims" | "exposure";
    assessmentFilter: DamageAssessment | null;
};

export type SetFetchingStatusPayload = boolean;
export type InsightsType = "noInsights" | "noAffectedProperties" | null;

export const AssessmentKeys = ["claims_revision", "exposure_revision"] as const;

export interface InsightsSourceConfig {
    id: string;
    type: string;
    data: GeoJSON.FeatureCollection;
    layers: string[];
    cluster: boolean;
    clusterRadius: number;
    viewOn: "left" | "right" | "both";
    currentLayer?: string;
}

export interface MarkerConfig {
    lnglat?: number[];
    cluster?: { [key: string]: number };
    clusterId: number;
}

export type assessmentFilters = {
    [key in AssessmentType]: DamageAssessment[];
};

export interface InsightsState {
    isFetchingInsights: boolean;
    isInsightsLoaded: boolean;
    insightsType: InsightsType;
    selectedPortfolio: PortfolioItem | null;
    selectedRevisionId: string | null;
    eventId: string;
    insightsData: InsightsSchema | null;
    policyData: {
        data: Record<string, any>[];
        stats: {
            total_estimated_exposure: number;
            total_exposed_policies: number;
        };
    } | null;
    revisions: RevisionSchema[];
    locationData: Papa.ParseResult<any> | null;
    insightsFilters: {
        damageLabels: string[];
        contractIds: Number[] | null;
    };
    selectedPeril: PerilType | null;
    insightsGeojson: GeoJSON.FeatureCollection | null;
    clusterToggle: boolean;
    assessmentType: AssessmentType;
    insightsVisible: boolean;
    insightsViewOn: "left" | "right" | "both";
    marker: MarkerConfig | null;
    assessmentFilters: assessmentFilters;
    allInsightsStyles: StyleConfig_Style[];
    insightsStyles: { [key in AssessmentType]: StyleConfig_Style } | null;
}

export interface SetInsightsLayerVisibilityPayload {
    id: string;
    visibility: Visibility;
}

export interface SetLayerInsightsLayerViewPayload {
    viewOn: "left" | "both" | "right";
}

export type toggleDamageLabelFilterPayload = {
    damageLabel: string;
};

export interface SetInsightsVisibilityPayload {
    visibility: Visibility;
}

export interface SetContractIdFilterPayload {
    contractIds: number[] | null;
}

export interface ClearInsightsFiltersPayload {}

export interface setSelectedPortfolioPayload {
    selectedPortfolio: PortfolioItem | null;
}

export interface SetEventIdPayload {
    eventId: string;
}

export interface ToggleMarkerPayload {
    marker: MarkerConfig | null;
}

export interface LoadInsightsPackagePayload {
    insightsData: InsightsSchema | null;
    eventId: string;
    eventType: EventType;
    portfolio: string;
}

export interface LoadInsightsPayload {
    locationData: Papa.ParseResult<any> | null;
    insightsGeojson: GeoJSON.FeatureCollection | null;
    insightsData: InsightsSchema | null;
    eventId: string;
    eventType: EventType;
    portfolio: string;
}

export interface UpdateAssesmentStylesPayload {
    locationData: Papa.ParseResult<any> | null;
    insightsData: InsightsSchema | null;
    assessmentType: AssessmentType;
}

export interface SetFilteredIndicesPayload {
    indices: number[];
}

export interface SetSelectedPerilPayload {
    selectedPeril: PerilType;
}
export interface SetFilteredPropertiesPayload {
    property: string;
    values: string[] | number[] | null;
}

export interface SetPerilTypePayload {
    perilType: EventType;
}

export type SetRevisionsPayload = RevisionSchema[];

export interface SetAssessmentTypePayload {
    assessmentType: AssessmentType;
}
export interface SetFetchingStatusAction extends AnyAction {
    type: typeof SET_FETCHING_STATUS;
    payload: SetFetchingStatusPayload;
}

export interface RemoveInsightsDataAction extends AnyAction {
    type: typeof REMOVE_INSIGHTS_DATA;
}

export interface SetInsightsLoadedAction extends AnyAction {
    type: typeof SET_INSIGHTS_LOADED;
    payload: boolean;
}

export interface SetInsightsTypeAction {
    type: typeof SET_INSIGHTS_TYPE;
    payload: SetInsightsTypePayload;
}

export interface SetInsightsLayerVisibilityAction extends AnyAction {
    type: typeof SET_INSIGHTS_LAYER_VISIBILITY;
    payload: SetInsightsLayerVisibilityPayload;
}

export interface SetInsightsLayerViewAction extends AnyAction {
    type: typeof SET_INSIGHTS_LAYER_VIEW;
    payload: SetLayerInsightsLayerViewPayload;
}

export interface toggleDamageLabelFilterAction extends AnyAction {
    type: typeof TOGGLE_DAMAGE_LABEL_FILTER;
    payload: toggleDamageLabelFilterPayload;
}

export interface setContractIdFilterAction extends AnyAction {
    type: typeof SET_CONTRACT_ID_FILTER;
    payload: SetContractIdFilterPayload;
}

export interface clearAllFiltersAction extends AnyAction {
    type: typeof CLEAR_INSIGHTS_FILTERS;
}

export interface SetInsightsVisibilityAction extends AnyAction {
    type: typeof TOGGLE_INSIGHTS_VISIBILITY;
    payload: SetInsightsVisibilityPayload;
}

export interface setSelectedPortfolioAction extends AnyAction {
    type: typeof SET_SELECTED_PORTFOLIO;
    payload: setSelectedPortfolioPayload;
}
export interface SetEventIdAction extends AnyAction {
    type: typeof SET_EVENT_ID;
    payload: SetEventIdPayload;
}

export interface ToggleMarkerAction extends AnyAction {
    type: typeof TOGGLE_MARKER;
    payload: ToggleMarkerPayload;
}

export interface ToggleClusterAction extends AnyAction {
    type: typeof TOGGLE_CLUSTER;
}

export interface AddPolicyInsightsAction extends AnyAction {
    type: typeof ADD_POLICY_INSIGHTS;
    payload: {
        data: Record<string, any>[];
        stats: {
            total_estimated_exposure: number;
            total_exposed_policies: number;
        };
    } | null;
}

export interface LoadInsightsAction extends AnyAction {
    type: typeof LOAD_INSIGHTS;
    payload: LoadInsightsPayload;
}

export interface SetInsightsGeojsonAction extends AnyAction {
    type: typeof SET_INSIGHTS_GEOJSON;
    payload: GeoJSON.FeatureCollection | null;
}

export interface SetAssessmentTypeAction extends AnyAction {
    type: typeof SET_ASSESSMENT_TYPE;
    payload: SetAssessmentTypePayload;
}

export interface UpdateAssessmentStylesAction extends AnyAction {
    type: typeof UPDATE_ASSESSMENT_STYLES;
    payload: UpdateAssesmentStylesPayload;
}

export interface SetFilteredIndicesAction extends AnyAction {
    type: typeof SET_FILTERED_INDICES;
    payload: SetFilteredIndicesPayload;
}

export interface SetFilteredPropertiesAction extends AnyAction {
    type: typeof SET_FILTERED_PROPERTIES;
    payload: SetFilteredPropertiesPayload;
}

export interface SetAssessmentFiltersAction extends AnyAction {
    type: typeof SET_ASSESSMENT_FILTERS;
    payload: SetAssessmentFiltersPayload;
}

export interface ClearFilteredIndicesAction extends AnyAction {
    type: typeof CLEAR_FILTERED_INDICES;
}

export interface SetSelectedPerilAction extends AnyAction {
    type: typeof SET_SELECTED_PERIL;
    payload: SetSelectedPerilPayload;
}

export interface SetRevisionsAction extends AnyAction {
    type: typeof SET_REVISIONS;
    payload: SetRevisionsPayload;
}

export interface SetSelectedRevisionIdAction extends AnyAction {
    type: typeof SET_SELECTED_REVISION_ID;
    payload: string;
}

export interface SetPerilTypeAction extends AnyAction {
    type: typeof SET_PERIL_TYPE;
    payload: SetPerilTypePayload;
}

export interface SetAllInsightsStylesAction extends AnyAction {
    type: typeof SET_ALL_INSIGHTS_STYLES;
    payload: StyleConfig_Style[];
}

export interface SetInsightsStylesAction extends AnyAction {
    type: typeof SET_INSIGHTS_STYLES;
    payload: { [key in AssessmentType]: StyleConfig_Style };
}

export type InsightsActionTypes =
    | RemoveInsightsDataAction
    | SetInsightsTypeAction
    | SetInsightsLoadedAction
    | SetInsightsLayerVisibilityAction
    | SetInsightsLayerViewAction
    | toggleDamageLabelFilterAction
    | SetInsightsVisibilityAction
    | setSelectedPortfolioAction
    | SetEventIdAction
    | SetFetchingStatusAction
    | ToggleMarkerAction
    | ToggleClusterAction
    | AddPolicyInsightsAction
    | SetInsightsGeojsonAction
    | LoadInsightsAction
    | SetAssessmentTypeAction
    | clearAllFiltersAction
    | SetFilteredIndicesAction
    | ClearFilteredIndicesAction
    | SetSelectedPerilAction
    | setContractIdFilterAction
    | SetRevisionsAction
    | SetSelectedRevisionIdAction
    | SetPerilTypeAction
    | setContractIdFilterAction
    | SetFilteredPropertiesAction
    | SetAssessmentFiltersAction
    | SetAllInsightsStylesAction
    | SetInsightsStylesAction
    | UpdateAssessmentStylesAction;
