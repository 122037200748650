import { ReactNode, RefObject } from "react";
import { AnyAction } from "redux";
import { MapRef } from "react-map-gl";
import { Modal } from "../../components/_Library/Modal/Modal";

// ACTION TYPE CONSTANTS
export const SET_REF = "SET_REF";

// ACTION TYPES
export interface SetRefAction extends AnyAction {
    type: typeof SET_REF;
    payload: SetRefPayload;
}

export interface SetRefPayload {
    refName: RefTypes;
    ref: RefObject<ReactNode> | null;
}

type RefTypes = keyof RefState;

export type RefActionTypes = SetRefAction;

// REDUCER TYPES
export interface RefState {
    modalRef: RefObject<typeof Modal>;
    mapRef: RefObject<MapRef>;
}
