
import { useUserMeAccessQuery } from "crud/me_access";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export interface ReturnType {
    loading: boolean;
    hasPermission: boolean;
}

export const useDownloadPermission = () => {
    const user = useSelector((state: RootState) => state.user?.user);
    const selectedPortfolioId = useSelector(
        (state: RootState) => state?.insights?.selectedPortfolio?.id ||
            user?.portfolios[0]?.id ||
            null,
    );
    const { data, isPending } = useUserMeAccessQuery();

    
    const shouldDisableDownloads = useMemo(() => {
        if (user?.is_admin) return false;
        if (!user || !user?.portfolios) {
            return true;
        }
        if (isPending) return true;
        if (!selectedPortfolioId) return true;
        const isAllowed = _.get(data?.insights_access, selectedPortfolioId, false);
        return !isAllowed;
    }, [user, isPending, selectedPortfolioId, data]);

    return {
        disable: shouldDisableDownloads,
        loading: isPending,
    };
};
