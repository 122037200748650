import React, { ReactElement, useState } from "react";
import classes from "./HelpDropdown.module.css";
import { Link } from "react-router-dom";

import Icon from "@mdi/react";
import {
    mdiInformation,
    mdiOpenInNew,
    mdiStar,
    mdiFileQuestion,
} from "@mdi/js";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import { IconWorldSearch } from "@tabler/icons-react";

interface LinkWithIcon {
    name: string;
    iconLeft: ReactElement;
    pathname?: string;
    modal?: boolean;
    newBadge?: boolean;
    external?: boolean;
}

const menuItems: LinkWithIcon[] = [
    { 
        name: "Leave Feedback", 
        iconLeft: <Icon path={mdiStar} size={1}/>, 
        modal: true, 
        newBadge: true 
    },
    {
        name: "Investigations", 
        iconLeft: <IconWorldSearch size={16}/>, 
        pathname: "https://mckenzieintelligence.com/investigations",
        external: true
    },
    {
        name: "Support Documentation",
        iconLeft: <Icon path={mdiFileQuestion} size={1}/>,
        pathname: "https://help.misgeo.io/en/",
        external: true,
    },
    {
        name: "Status",
        iconLeft: <Icon path={mdiInformation} size={1}/>,
        pathname: "https://status.misgeo.io/",
        external: true,
    },
];

interface StateProps {
    closeDropdown: any;
    setHelpMenuVisible: any;
}

type AllProps = StateProps;

function HelpDropdown({ closeDropdown, setHelpMenuVisible }: AllProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { trackUserEvent } = useAnalytics();
    const buildLinkProps = (menuItem: LinkWithIcon) => {
        return {
            to: {
                pathname: menuItem?.pathname,
                state: {
                    section: menuItem.name,
                },
            },
            target: menuItem?.pathname ? "_blank" : "",
        };
    };

    const closeModal = () => {
        setHelpMenuVisible(true);
        setModalOpen(false);
        closeDropdown();
    };

    const openFeedbackModal = () => {
        trackUserEvent({ name: "leave_feedback_clicked" });
        setModalOpen(true);
        setHelpMenuVisible(false);
    };

    return (
        <div className={classes.MenuContainer}>
            {menuItems.map((menuItem) =>
                menuItem.modal ? (
                    <div
                        className={classes.MenuItem}
                        onClick={() => openFeedbackModal()}
                    >
                        {menuItem.iconLeft}
                        {menuItem.name}
                        {menuItem.newBadge && (
                            <span className={classes.New}>NEW</span>
                        )}
                    </div>
                ) : (
                    <Link {...buildLinkProps(menuItem)}>
                        <div className={classes.MenuItem}>
                            {menuItem.iconLeft}
                            {menuItem.name}
                            {menuItem.external && (
                                <Icon path={mdiOpenInNew} size={0.75} />
                            )}
                        </div>
                    </Link>
                ),
            )}

            {modalOpen && <FeedbackModal closeModal={closeModal} />}
        </div>
    );
}

export default HelpDropdown;
