import React, { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from "@mdi/js/commonjs/mdi";
import cx from "classnames";

import { ConfigMenuGroup as MenuIndexGroup } from "../../../../../../../../../store/system/systemTypes";
import {
    ConfigMenuGroup,
    ConfigMenuLayer,
} from "../../../../../../../../../store/system/systemTypes";
import {
    ConfigSource,
    Visibility,
} from "../../../../../../../../../store/report/reportTypes";
import {
    setLayerView,
    setLayerVisibility,
} from "../../../../../../../../../store/report/reportActions";
import { RootState } from "../../../../../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import ScrollableText from "../../../../../../../../_Library/ScrollableText/ScrollableText";
import LayerIcon from "../../../../LayerIcon/LayerIcon";

import classes from "../LayerListItem/LayerListItem.module.css";
import { isZoomToBBox } from "../../../../../../../../../utils/TypeGuards";
import Toggle from "../../../../../../../../_Library/Inputs/Toggle/Toggle";
import { Badges } from "components/_Library/Badges/Badges";
import { ActionIcon, Popover, Tooltip } from "@mantine/core";

import LayerContextMenu from "../LayerContextMenu/LayerContextMenu";
import { DownloadsContext } from "components/Pages/Report/Report";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { BBox2d } from "@turf/helpers/dist/js/lib/geojson";

interface GroupedLayersListItemProps {
    group: MenuIndexGroup;
    beta?: boolean;
    tier?: string;
    downloadsAvailable?: boolean;
    visible: boolean;
}

const GroupedLayersListItem: React.FC<GroupedLayersListItemProps> = ({
    group,
    tier,
    beta,
    downloadsAvailable,
    visible,
}) => {
    const dispatch = useDispatch();
    const { downloadModalOpen } = useContext(DownloadsContext);
    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const layersConfig = useSelector((state: RootState) => {
        return getStoreAtNamespaceKey(state, "report").layersConfig;
    });

    const bboxFromBboxes = (bboxes: BBox2d[]): BBox2d | null => {
        // Find the bounding box by
        // using the min and max values
        if (bboxes.length === 0) {
            return null;
        }
        let [minX, minY, maxX, maxY] = bboxes[0];
        for (let i = 1; i < bboxes.length; i++) {
            const [bboxMinX, bboxMinY, bboxMaxX, bboxMaxY] = bboxes[i];
            minX = Math.min(minX, bboxMinX);
            minY = Math.min(minY, bboxMinY);
            maxX = Math.max(maxX, bboxMaxX);
            maxY = Math.max(maxY, bboxMaxY);
        }
        return [minX, minY, maxX, maxY];
    };

    const getLayerBbox = useCallback((layer: ConfigSource) => {
        if (isZoomToBBox(layer.actions?.zoomTo)) {
            return layer.actions?.zoomTo?.bbox;
        }
        return null;
    }, []);

    const getSourceFromLayer = useCallback(
        (layer: ConfigMenuLayer) => {
            return layersConfig?.sources[layer.layerSource];
        },
        [layersConfig],
    );

    const getLayerSources = useCallback(
        (group: ConfigMenuGroup) => {
            let sources: ConfigSource[] = [];
            group.children.forEach((child) => {
                if (child.type === "layer") {
                    const layerFullSource = getSourceFromLayer(child);
                    if (layerFullSource)
                        sources = sources.concat(layerFullSource);
                } else {
                    sources = sources.concat(getLayerSources(child));
                }
            });
            return sources;
        },
        [getSourceFromLayer],
    );

    const groupBbox = useMemo(() => {
        const allSources = getLayerSources(group)
            .map((source) => getLayerBbox(source) as BBox2d)
            .filter((f) => f !== null);
        return bboxFromBboxes(allSources);
    }, [getLayerBbox, getLayerSources, group]);

    const containedSources = useMemo(
        () => getLayerSources(group),
        [getLayerSources, group],
    );
    const mapRef = useSelector((state: RootState) => state.ref?.mapRef);

    const toggleLayerVisibility = useCallback(
        (group: ConfigMenuGroup, status: string) => {
            let newVisibility: Visibility =
                status === "show" ? "visible" : "none";
            let viewOnCount = {
                left: 0,
                both: 0,
                right: 0,
            };
            containedSources.forEach((source) => {
                ++viewOnCount[source.viewOn];
            });

            let determinedView = Object.keys(viewOnCount).reduce((a, b) => {
                const keyA = a as keyof typeof viewOnCount;
                const keyB = b as keyof typeof viewOnCount;
                return viewOnCount[keyA] > viewOnCount[keyB] ? keyA : keyB;
            });
            group.children.forEach((child) => {
                if (child.type === "layer") {
                    dispatch(
                        setLayerVisibility({
                            sourceName: child.layerSource,
                            layerName: child.layerName,
                            visibility: newVisibility,
                        }),
                    );
                } else {
                    toggleLayerVisibility(child, status);
                }
            });
            trackUserEventWithCurrentEvent({
                name: "data_layer_visibility_toggled",
                payload: {
                    layer_id: group.id,
                    layer_name: group.groupName,
                    state: visible ? "off" : "on",
                    view: determinedView as "left" | "both" | "right",
                },
            });
        },
        [containedSources, dispatch, trackUserEventWithCurrentEvent, visible],
    );

    const handleZoomToLayer = useCallback(() => {
        if (!groupBbox || !mapRef || !mapRef.current) return;
        mapRef?.current.fitBounds(groupBbox);
        trackUserEventWithCurrentEvent({
            name: "go_to_clicked",
            payload: {
                layer_id: group.id,
                layer_name: group.groupName,
            },
        });
    }, [
        groupBbox,
        mapRef,
        trackUserEventWithCurrentEvent,
        group.id,
        group.groupName,
    ]);

    const setGroupView = useCallback(
        (group: ConfigMenuGroup, view: "left" | "right" | "both") => {
            group.children.forEach((child) => {
                if (child.type === "layer") {
                    dispatch(
                        setLayerView({
                            layerName: child.layerName,
                            sourceName: child.layerSource,
                            viewOn: view,
                        }),
                    );
                } else {
                    setGroupView(child, view);
                }
            });
        },
        [dispatch],
    );

    const renderContextMenu = useCallback(() => {
        let viewOnCount = { left: 0, both: 0, right: 0 };
        containedSources.forEach((source) => {
            ++viewOnCount[source?.viewOn];
        });
        const currentView =
            viewOnCount.left > viewOnCount.both &&
            viewOnCount.left > viewOnCount.right
                ? "left"
                : viewOnCount.right > viewOnCount.both
                ? "right"
                : "both";
        return (
            <LayerContextMenu
                onZoomTo={handleZoomToLayer}
                onViewSelection={(view) => setGroupView(group, view)}
                currentView={currentView}
                name={group.groupName}
                downloadsAvailable={downloadsAvailable}
                type="group_as_layer"
            />
        );
    }, [
        containedSources,
        group,
        handleZoomToLayer,
        downloadsAvailable,
        setGroupView,
    ]);

    return (
        <div className={cx(classes.LayerItem)}>
            <div className={classes.Header}>
                <div
                    className={cx(classes.ToggleTitle, {
                        [classes.ToggleTitleWithBadges]:
                            beta || tier !== "basic",
                    })}
                >
                    <Tooltip label={"Toggle Layer Visibility"}>
                        <span className={classes.VisibilityIcon}>
                            <Toggle
                                heightRem={"1.5rem"}
                                active={visible}
                                onClick={() =>
                                    toggleLayerVisibility(
                                        group,
                                        visible ? "hide" : "show",
                                    )
                                }
                            />
                        </span>
                    </Tooltip>
                    <span className={classes.Label}>
                        <ScrollableText text={group.groupName} />
                    </span>
                </div>
                <div className={classes.Icons}>
                    {(beta || tier !== "basic") && (
                        <Badges beta={beta} tier={tier} />
                    )}
                    <div className={classes.LayerIcon}>
                        <LayerIcon
                            complexLegend={false}
                            paint={{}}
                            type={"raster"}
                        />
                    </div>
                    <Popover
                        position={"top-end"}
                        classNames={{
                            dropdown: classes.ContextMenu,
                        }}
                        disabled={downloadModalOpen}
                    >
                        <Popover.Target>
                            <ActionIcon variant="subtle">
                                <Icon
                                    path={mdiDotsHorizontal}
                                    className={classes.ContextMenuIcon}
                                />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            {renderContextMenu()}
                        </Popover.Dropdown>
                    </Popover>
                </div>
            </div>
        </div>
    );
};

export default GroupedLayersListItem;
