import React, { FC } from "react";
import cx from "classnames";
import classes from "./SearchHistory.module.css";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { useDispatch } from "react-redux";
import { MapRef } from "react-map-gl";
import { setMapMarkerLocation } from "store/report/reportActions";
import ScrollableText from "components/_Library/ScrollableText/ScrollableText";
import { SearchesType } from "../GeocoderTab";

interface SearchHistoryProps {
    mapRef: React.RefObject<MapRef>;
    pastSearches: SearchesType;
    setPastSearches: React.Dispatch<React.SetStateAction<SearchesType>>;
}

const SearchHistory: FC<SearchHistoryProps> = ({
    mapRef,
    pastSearches,
    setPastSearches,
}: SearchHistoryProps) => {
    const dispatch = useDispatch();

    let history = pastSearches;

    return (
        <div className={classes.PastSearch}>
            <div className={classes.PastSearchHeader}>
                <h4>Search History</h4>
                <div
                    className={cx(classes.Clickable, classes.MassDelete)}
                    onClick={() => {
                        localStorage.removeItem("pastSearches");
                        setPastSearches({ searches: [] });
                        let searchBox = document.getElementsByClassName(
                            "mapboxgl-ctrl-geocoder--input",
                        )[0] as HTMLInputElement;
                        searchBox.value = "";
                    }}
                >
                    <p>Delete All</p>
                    <Icon path={mdiTrashCan} size={1} />
                </div>
            </div>

            <div className={classes.HistoryContainer}>
                {history.searches.reverse().map((search) => {
                    return (
                        <div
                            className={classes.HistoryResult}
                            key={search.place_name}
                        >
                            <div
                                className={cx(
                                    classes.Clickable,
                                    classes.SearchItem,
                                )}
                            >
                                <div
                                    className={classes.SearchText}
                                    onClick={() => {
                                        let searchBox =
                                            document.getElementsByClassName(
                                                "mapboxgl-ctrl-geocoder--input",
                                            )[0] as HTMLInputElement;
                                        searchBox.value = search.place_name;

                                        if (search.bbox) {
                                            mapRef.current?.fitBounds(
                                                search.bbox,
                                            );
                                        } else {
                                            mapRef.current?.flyTo({
                                                center: search.center as [
                                                    number,
                                                    number,
                                                ],
                                                zoom: 12,
                                            });
                                        }

                                        dispatch(
                                            setMapMarkerLocation(
                                                search.center as [
                                                    number,
                                                    number,
                                                ],
                                            ),
                                        );
                                    }}
                                >
                                    <ScrollableText text={search.place_name} />
                                </div>
                            </div>
                            <div
                                className={cx(
                                    classes.Clickable,
                                    classes.SearchDelete,
                                )}
                                onClick={() => {
                                    let newHistory =
                                        pastSearches.searches.filter(
                                            (object) => {
                                                return (
                                                    object.place_name !==
                                                    search.place_name
                                                );
                                            },
                                        );

                                    setPastSearches({
                                        searches: newHistory,
                                    });

                                    if (newHistory.length === 0) {
                                        localStorage.removeItem("pastSearches");
                                    } else {
                                        localStorage.setItem(
                                            "pastSearches",
                                            JSON.stringify(newHistory),
                                        );
                                    }

                                    let searchBox =
                                        document.getElementsByClassName(
                                            "mapboxgl-ctrl-geocoder--input",
                                        )[0] as HTMLInputElement;
                                    searchBox.value = "";
                                }}
                            >
                                <Icon path={mdiTrashCan} size={1} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SearchHistory;
