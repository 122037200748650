/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */

import React, { ReactElement } from "react";
import * as MapboxGL from "mapbox-gl";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiCamera, mdiShape } from "@mdi/js";

import LineIcon from "./LineIcon/LineIcon";
import PointIcon from "./PointIcon/PointIcon";
import PolygonIcon from "./PolygonIcon/PolygonIcon";
import {
    mdiMIS_legendLine,
    mdiMIS_legendPoint,
    mdiMIS_legendPoly,
} from "assets/icons/paths";

import classes from "./LayerIcon.module.css";
import { AnyLayer } from "react-map-gl";

export interface OwnProps {
    complexLegend: boolean;
    paint: MapboxGL.AnyPaint;
    type: AnyLayer["type"];
}

const isCirclePaint = (
    paint: MapboxGL.AnyPaint,
): paint is MapboxGL.CirclePaint => {
    return (paint as MapboxGL.CirclePaint)["circle-color"] !== undefined;
};

const isFillPaint = (paint: MapboxGL.AnyPaint): paint is MapboxGL.FillPaint => {
    return (paint as MapboxGL.FillPaint)["fill-color"] !== undefined;
};

const isLinePaint = (paint: MapboxGL.AnyPaint): paint is MapboxGL.LinePaint => {
    return (paint as MapboxGL.LinePaint)["line-color"] !== undefined;
};

const LayerIcon: React.FC<OwnProps> = (props: OwnProps) => {
    let layerIcon: ReactElement | null = null;
    switch (props.type) {
        case "fill":
            if (isFillPaint(props.paint)) {
                layerIcon = props.complexLegend ? (
                    <div className={cx(classes.Icon, classes.Legend)}>
                        <Icon path={mdiMIS_legendPoly} />
                    </div>
                ) : (
                    <PolygonIcon paint={props.paint} />
                );
            }
            break;
        case "circle":
            if (isCirclePaint(props.paint)) {
                layerIcon = props.complexLegend ? (
                    <div className={cx(classes.Icon, classes.Legend)}>
                        <Icon path={mdiMIS_legendPoint} />
                    </div>
                ) : (
                    <PointIcon paint={props.paint} />
                );
            }
            break;
        case "line":
            if (isLinePaint(props.paint)) {
                layerIcon = props.complexLegend ? (
                    <div className={cx(classes.Icon, classes.Legend)}>
                        <Icon path={mdiMIS_legendLine} />
                    </div>
                ) : (
                    <LineIcon paint={props.paint} />
                );
            }
            break;
        case "raster":
            layerIcon = (
                <div className={cx(classes.Icon)}>
                    <Icon path={mdiCamera} />
                </div>
            );
            break;
        case "symbol":
            layerIcon = (
                <div className={cx(classes.Icon, classes.Legend)}>
                    <Icon path={mdiShape} />
                </div>
            );
            break;
    }
    return layerIcon;
};

export default LayerIcon;
